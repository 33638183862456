import React, {useState, FC, PropsWithChildren} from 'react';
import {Grid, Typography, Button, InputAdornment, TextField} from '@mui/material';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {AddCircle, Search} from '@mui/icons-material';
import Skeleton from '@mui/lab/Skeleton';
import {Branch} from 'pay-by-loan-administrator-api';
import styles from './style.module.scss';

type Props = {
    branchList: Array<Branch>;
    onAddNewBranch: () => void;
    onDeleteBranch: (branchId: string) => void;
    defaultPageNumber?: number;
    onPageNumberChanged: (pageNumber: number) => void;
};

const BranchList: FC<PropsWithChildren<Props>> = ({branchList, onAddNewBranch, onDeleteBranch, defaultPageNumber, onPageNumberChanged}) => {
    const [pageSize, setPageSize] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    const editSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.currentTarget.value);
    };

    const handleDeleteClick = (branchId: string) => {
        onDeleteBranch(branchId);
    };

    const columnsSkeleton: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Ime',
            width: 250,
            renderCell: () => {
                return <Skeleton variant="text" width={100} height={20} animation="wave" />;
            },
        },
        {
            field: ' ',
            headerName: ' ',
            width: 90,
            renderCell: () => {
                return <></>;
            },
        },
    ];

    const columns: GridColDef[] = [
        {field: 'name', headerName: 'Ime', width: 250},
        {
            field: ' ',
            headerName: ' ',
            width: 90,
            renderCell: (params) => {
                return (
                    <strong>
                        <Button variant="contained" color="primary" size="small" onClick={() => handleDeleteClick(params.row.id)}>
                            OBRIŠI
                        </Button>
                    </strong>
                );
            },
        },
    ];

    const rows = (): Array<Branch> => {
        if (branchList === undefined) {
            return [...Array(10)].map((_, i) => {
                return {id: '', name: ''};
            });
        }

        return branchList.filter((branch) => branch.name.toLowerCase().includes(searchTerm));
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
    };

    return (
        <Grid container direction="column" justifyContent="center" className={styles.mainContainer}>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" className={styles.verticalMargin}>
                <Grid container direction="row" alignItems="center" className={styles.addNewContainer}>
                    <Button color="primary" className={styles.addNewButton} onClick={() => onAddNewBranch()}>
                        <AddCircle />
                        <Typography variant="body1" className={styles.addNewText}>
                            Dodaj novu poslovnicu
                        </Typography>
                    </Button>
                </Grid>
                <TextField
                    name="search"
                    onChange={editSearchTerm}
                    type="text"
                    label="Pretraži:"
                    value={searchTerm}
                    className={styles.filter}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color="primary" />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <div style={{width: '100%'}}>
                <DataGrid
                    autoHeight
                    disableSelectionOnClick
                    disableColumnMenu
                    disableDensitySelector
                    page={defaultPageNumber}
                    onPageChange={(newPageNumber: number) => {
                        onPageNumberChanged(newPageNumber);
                    }}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    pagination
                    rows={rows()}
                    columns={branchList !== undefined ? columns : columnsSkeleton}
                />
            </div>
        </Grid>
    );
};

export default BranchList;
