import {useEffect, useState} from 'react';
import {
    Button,
    Grid,
    FormGroup,
    TextField,
    MenuItem,
    FormControl,
    FormHelperText,
    ButtonGroup,
    Typography,
    Card,
    CardContent,
    CardHeader,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    InputAdornment,
    createTheme,
    ThemeProvider,
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {IProduct} from '../../lib/interfaces/interfaces';
import axios from 'axios';
import Loader from '../loader';
import ErrorSnackbar from '../errorSnackbar';
import useAuth from '../../Auth/useAuth';
import NumberFormat from 'react-number-format';
import styles from './style.module.scss';

const theme = createTheme({
    components: {
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    border: 'none',
                    outline: 'none',
                },
            },
        },
    },
});

const ProductInfoScreen = ({submitForm, cancel, amount}) => {
    const auth = useAuth();
    const {control, handleSubmit, watch} = useForm();
    const [showLoader, setShowLoader] = useState(true);
    const [productList, setProductList] = useState<Array<IProduct>>();
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const watchProduct = watch('productId');
    const watchCurrency = watch('currency', 'HRK');

    useEffect(() => {
        auth.areTokensValid()
            .then((resolve) => {
                axios
                    .get(`${process.env.REACT_APP_PRODUCTS_BASE_URL}/api/v1/products`, {
                        headers: {
                            Authorization: `Bearer ${resolve.token}`,
                        },
                    })
                    .then((response) => {
                        setProductList(response.data.products);
                        setShowLoader(false);
                    })
                    .catch(() => {
                        setErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                        setOpenError(true);
                        setShowLoader(false);
                        setTimeout(() => setOpenError(false), 4000);
                    });
            })
            .catch(() => {
                window.location.reload();
            });
    }, []);

    const defaultProduct = () => {
        if (productList && productList.length > 0) {
            if (productList.length > 1) {
                return watchProduct;
            }

            return productList[0].id;
        }
    };

    const formatCurrencyByEnd = (value: string): string => {
        if (!Number(value)) return '';
        const amount = new Intl.NumberFormat('de-DE', {
            minimumFractionDigits: 2,
        }).format(parseFloat(value) / 100);
        return `${amount}`;
    };

    return (
        <>
            <ErrorSnackbar open={openError} message={errorMessage} />
            {showLoader ? (
                <Loader appear={true} />
            ) : (
                <FormGroup>
                    <Grid container direction="row" alignItems="center" justifyContent="space-between" className={styles.inputMargin} style={{marginBottom: 0}}>
                        <>
                            <FormControl variant="outlined" className={styles.inputMargin} style={{width: '45%'}}>
                                <TextField select label="SREDSTVO PLAĆANJA" defaultValue="kent">
                                    <MenuItem value="kent">Kentbank</MenuItem>
                                </TextField>
                            </FormControl>
                            <Controller
                                name="productId"
                                control={control}
                                defaultValue={defaultProduct}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <FormControl variant="outlined" className={styles.inputMargin} style={{width: '45%'}}>
                                        <TextField select label="PROIZVOD" value={value ?? ''} onChange={onChange} error={!!error}>
                                            {productList?.map((product) => (
                                                <MenuItem value={product.id} key={product.name}>
                                                    {product.name}
                                                </MenuItem>
                                            ))}
                                        </TextField>
                                        {error && <FormHelperText style={{color: '#f44336'}}>Potrebno odabrati proizvod.</FormHelperText>}
                                    </FormControl>
                                )}
                                rules={{required: true}}
                            />
                        </>
                    </Grid>
                    {watchProduct && (
                        <>
                            <Grid
                                container
                                direction="row"
                                alignItems="center"
                                justifyContent="space-between"
                                className={styles.inputMargin}
                                style={{marginBottom: 0}}>
                                <>
                                    <TextField
                                        className={styles.inputMargin}
                                        style={{width: '45%'}}
                                        label="MIN ROK OTPLATE"
                                        variant="outlined"
                                        value={`${productList?.find((product) => product.id === defaultProduct())?.minFundingDeadline}`}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                    <TextField
                                        className={styles.inputMargin}
                                        style={{width: '45%'}}
                                        label="MAX ROK OTPLATE"
                                        variant="outlined"
                                        value={`${productList?.find((product) => product.id === defaultProduct())?.maxFundingDeadline}`}
                                        InputProps={{
                                            readOnly: true,
                                        }}
                                    />
                                </>
                            </Grid>
                            <Controller
                                name="amount"
                                control={control}
                                defaultValue={amount * 100 ? formatCurrencyByEnd(`${amount}00`) : ''}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <NumberFormat
                                        className={styles.inputMargin}
                                        style={{width: '45%', marginBottom: '2rem', textAlign: 'right'}}
                                        label="UNESITE IZNOS"
                                        placeholder="5.000,00"
                                        format={formatCurrencyByEnd}
                                        customInput={TextField}
                                        InputProps={{
                                            endAdornment: (
                                                <InputAdornment position="end">
                                                    <Controller
                                                        name="currency"
                                                        control={control}
                                                        defaultValue={
                                                            productList?.find((product) => product.id === defaultProduct())?.interestRates[0].currency
                                                        }
                                                        render={({field: {onChange, value}, fieldState: {error}}) => (
                                                            <ThemeProvider theme={theme}>
                                                                <TextField select onChange={onChange} value={value ?? ''} error={!!error}>
                                                                    {productList
                                                                        ?.find((product) => product.id === defaultProduct())
                                                                        ?.interestRates.map((interest, i) => (
                                                                            <MenuItem key={i} value={interest.currency}>
                                                                                {interest.currency}
                                                                            </MenuItem>
                                                                        ))}
                                                                </TextField>
                                                            </ThemeProvider>
                                                        )}
                                                    />
                                                </InputAdornment>
                                            ),
                                        }}
                                        variant="outlined"
                                        allowNegative={false}
                                        fixedDecimalScale
                                        decimalScale={2}
                                        inputMode="numeric"
                                        displayType="input"
                                        value={value ?? ''}
                                        error={!!error}
                                        helperText={error?.message || null}
                                        onValueChange={(values) => {
                                            onChange((parseFloat(values.value) / 100).toFixed(2));
                                        }}
                                    />
                                )}
                                rules={{
                                    required: 'Potrebno unijeti iznos.',
                                    min: {
                                        value:
                                            (watchCurrency
                                                ? productList
                                                      ?.find((product) => product.id === defaultProduct())
                                                      ?.interestRates.find((interest) => interest.currency === watchCurrency)?.minFinancingAmount
                                                : productList?.find((product) => product.id === defaultProduct())?.interestRates[0].minFinancingAmount) ?? '',
                                        message: 'Iznos je prenizak.',
                                    },
                                    max: {
                                        value:
                                            (watchCurrency
                                                ? productList
                                                      ?.find((product) => product.id === defaultProduct())
                                                      ?.interestRates.find((interest) => interest.currency === watchCurrency)?.maxFinancingAmount
                                                : productList?.find((product) => product.id === defaultProduct())?.interestRates[0].maxFinancingAmount) ?? '',
                                        message: 'Iznos je prevelik.',
                                    },
                                }}
                            />
                        </>
                    )}
                    {defaultProduct() &&
                        productList?.find((product) => product.id === defaultProduct())?.relatedDocuments &&
                        productList?.find((product) => product.id === defaultProduct())?.relatedDocuments?.length !== 0 && (
                            <>
                                POTREBNI DOKUMENTI:
                                <ul className={styles.inputMargin}>
                                    {productList
                                        .find((product) => product.id === defaultProduct())
                                        ?.relatedDocuments.map((document, i) => (
                                            <li key={i}>
                                                <Typography>{document}</Typography>
                                            </li>
                                        ))}
                                </ul>
                            </>
                        )}
                    {defaultProduct() && (
                        <Grid container alignItems="center" justifyContent="space-around">
                            {productList &&
                                productList
                                    ?.find((product) => product.id === defaultProduct())
                                    ?.interestRates.map((currency, i) => (
                                        <Grid item xs={12} md={5} key={i} style={{margin: '1rem'}}>
                                            <Card raised>
                                                <CardHeader title={`${currency.currency}`} />
                                                <CardContent>
                                                    <TableContainer>
                                                        <Table aria-label="simple table">
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell align="left">Vrsta kamate</TableCell>
                                                                    <TableCell align="right">{currency.interestRateType}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="left">Minimalni iznos kredita</TableCell>
                                                                    <TableCell align="right">{currency.minFinancingAmount.toLocaleString('de-DE')}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="left">Maksimalni iznos kredita</TableCell>
                                                                    <TableCell align="right">{currency.maxFinancingAmount.toLocaleString('de-DE')}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="left">Kamatna stopa</TableCell>
                                                                    <TableCell align="right">
                                                                        {`${currency.ktaWithStatusInBankTo}/${currency.ktaWithoutStatusInBankTo}`}
                                                                    </TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell align="left"></TableCell>
                                                                    <TableCell align="right">
                                                                        <Typography
                                                                            align="right"
                                                                            style={{
                                                                                fontStyle: 'italic',
                                                                            }}>
                                                                            *kamata za klijenta banke/kamata za ostale
                                                                        </Typography>
                                                                    </TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                </CardContent>
                                            </Card>
                                        </Grid>
                                    ))}
                        </Grid>
                    )}
                    <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="contained primary button group">
                        <Button onClick={handleSubmit(submitForm)}>Nastavi</Button>
                        <Button onClick={cancel}>Odustani</Button>
                    </ButtonGroup>
                </FormGroup>
            )}
        </>
    );
};

export default ProductInfoScreen;
