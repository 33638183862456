import {Snackbar} from '@mui/material';
import Alert from '@mui/lab/Alert';

const ErrorSnackbar = ({open, message}: {open: boolean; message?: string}) => {
    return (
        <div>
            <Snackbar
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                open={open}>
                <Alert severity="error">{message}</Alert>
            </Snackbar>
        </div>
    );
};

export default ErrorSnackbar;
