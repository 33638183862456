import {Button, Grid, Typography} from '@mui/material';
import styles from './style.module.scss';

const FinishScreen = ({pageStateSetting, success}) => {
    return (
        <Grid container direction="column" justifyContent="center" alignItems="center">
            {!success && (
                <>
                    <Typography variant="h5" color="secondary" className={styles.instructions} style={{marginBottom: '2rem'}}>
                        Nešto je pošlo po krivu. Pokušajte ispočetka.
                    </Typography>
                    <Button variant="outlined" color="secondary" onClick={() => pageStateSetting(0)} style={{marginTop: '2rem'}}>
                        OK
                    </Button>
                </>
            )}
            {success && (
                <>
                    <Typography variant="h5" color="primary" paragraph style={{textAlign: 'center'}}>
                        ZAHTJEV JE USPJEŠNO POSLAN
                    </Typography>
                    <Button variant="outlined" color="secondary" onClick={() => pageStateSetting(0)} style={{marginTop: '2rem'}}>
                        OK
                    </Button>
                </>
            )}
        </Grid>
    );
};

export default FinishScreen;
