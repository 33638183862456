import {useRef, useState} from 'react';
import {ButtonGroup, LinearProgress, Button, Typography} from '@mui/material';
import {AddAPhoto, SwitchCamera} from '@mui/icons-material';
import axios from 'axios';
import useAuth from '../../Auth/useAuth';
import CameraComponent, {ICameraComponent} from './cameraComponent';
import styles from './style.module.scss';

const CameraScreen = ({side, onDataScanned, onCancel}) => {
    const auth = useAuth();
    const cameraComponentRef = useRef<ICameraComponent>(null);

    const [showButtons, setShowButtons] = useState(true);
    const [view, setView] = useState<string>('camera');
    const [mirrored, setIsMirrored] = useState(false);

    let imageSendingInterval;
    let timesRun = 0;

    const tryAgain = () => {
        setView('camera');
    };

    const makePhoto = () => {
        timesRun = 0;
        cameraComponentRef.current!.startCapturing();
        setShowButtons(false);
        imageSendingInterval = setInterval(() => {
            getPhoto();
        }, 3000);
    };

    const getPhoto = async () => {
        if (cameraComponentRef.current) {
            timesRun += 1;

            const imageSrc = await cameraComponentRef.current.getScreenshot();

            if (imageSrc === null) {
                return;
            }

            submitPhoto(imageSrc);
        }
    };

    const submitPhoto = (imageSrc: string) => {
        auth.areTokensValid()
            .then((resolve) => {
                axios
                    .post(
                        `${process.env.REACT_APP_SIMPLE_ID_SCANNER}/api/v1/scan`,
                        {
                            image: imageSrc,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${resolve.token}`,
                            },
                        }
                    )
                    .then((response) => {
                        cameraComponentRef.current?.stopCamera();
                        setShowButtons(true);
                        if ((side === 'FRONT' && response.data.documentSide === 'BACK') || (side === 'BACK' && response.data.documentSide === 'FRONT')) {
                            setView('wrong_page_scanned');
                            clearInterval(imageSendingInterval);
                            return;
                        }

                        onDataScanned(response.data);
                        clearInterval(imageSendingInterval);
                    })
                    .catch((err) => {
                        if (err.response.status === 422 && err.response.data.type === 'WRONG_DOCUMENT') {
                            cameraComponentRef.current?.stopCamera();
                            clearInterval(imageSendingInterval);
                            setShowButtons(true);
                            setView('wrong_document_scanned');
                            return;
                        }
                        if (timesRun >= 5) {
                            cameraComponentRef.current?.stopCamera();
                            clearInterval(imageSendingInterval);
                            setShowButtons(true);
                            setView('no_data_scanned');
                        }
                    });
            })
            .catch(() => {
                window.location.reload();
            });
    };

    const handleOnCancel = () => {
        clearInterval(imageSendingInterval);
        cameraComponentRef.current!.stopCamera();
        setTimeout(() => {
            onCancel();
        }, 1000);
    };

    return (
        <>
            {view === 'no_data_scanned' && (
                <div className={styles.errorComp}>
                    <Typography variant="h5" color="secondary" className={styles.instructions} style={{marginBottom: '2rem'}}>
                        Neuspješno čitanje podataka. Pokušajte ponovno.
                    </Typography>
                    <Button variant="outlined" color="primary" onClick={tryAgain}>
                        Pokušaj ponovo
                    </Button>
                </div>
            )}
            {view === 'wrong_document_scanned' && (
                <div className={styles.errorComp}>
                    <Typography variant="h5" color="secondary" className={styles.instructions} style={{marginBottom: '2rem'}}>
                        Skeniran nepodržani dokument. Pokušajte ponovno.
                    </Typography>
                    <Button variant="outlined" color="primary" onClick={tryAgain}>
                        Pokušaj ponovo
                    </Button>
                </div>
            )}
            {view === 'wrong_page_scanned' && (
                <div className={styles.errorComp}>
                    <Typography variant="h5" color="secondary" className={styles.instructions} style={{marginBottom: '2rem'}}>
                        Skenirana pogrešna strana.
                    </Typography>
                    <Button variant="outlined" color="primary" onClick={tryAgain}>
                        Pokušaj ponovo
                    </Button>
                </div>
            )}
            {view === 'camera' && (
                <>
                    <ButtonGroup
                        style={{
                            alignSelf: 'center',
                            margin: '0 0 2rem 0',
                        }}>
                        <Button variant="outlined" color="secondary" onClick={() => setIsMirrored(!mirrored)}>
                            Zrcali kameru
                            <SwitchCamera fontSize="large" style={{marginLeft: '0.5rem'}} />
                        </Button>
                    </ButtonGroup>
                    <br />
                    <div className={styles.videoComp}>
                        {!showButtons && <LinearProgress />}
                        <CameraComponent ref={cameraComponentRef} mirrored={mirrored} captureFps={2} />
                    </div>
                    <ButtonGroup
                        style={{
                            alignSelf: 'center',
                            margin: '2rem 0 0 0',
                        }}>
                        <Button variant="outlined" color="secondary" onClick={makePhoto} disabled={!showButtons}>
                            Snimi iskaznicu
                            <AddAPhoto fontSize="large" style={{marginLeft: '0.5rem'}} />
                        </Button>
                        <Button variant="outlined" color="secondary" onClick={handleOnCancel}>
                            Odustani
                        </Button>
                    </ButtonGroup>
                    <Typography variant="h5" color="secondary" className={styles.instructions}>
                        {side === 'FRONT' ? 'Slikajte prednju stranu osobne iskaznice.' : 'Slikajte stražnju stranu osobne iskaznice.'}
                    </Typography>
                </>
            )}
        </>
    );
};

export default CameraScreen;
