import {useEffect, useState} from 'react';
import AgentList from './agentList';
import AgentForm, {AgentFormType} from './agentForm';
import ErrorSnackbar from '../errorSnackbar';
import DrawerNavigation from '../navigation/drawerNavigation';
import {SnackbarState} from '../../lib/interfaces/payByLoanAdministratorInterfaces';
import Loader from '../loader';
import {useAdministratorControllerApi, useBranchControllerApi} from '../../api/apis';
import {ErrorType} from '../../lib/constants/errorTypes';
import {Agent, Branch} from 'pay-by-loan-administrator-api';
import AppBarHeader from '../appBarHeader';
import styles from './style.module.scss';

enum PageState {
    AGENT_LIST = 0,
    AGENT_FORM = 1,
}

const AdminPage = () => {
    const administratorControllerApi = useAdministratorControllerApi();
    const branchControllerApi = useBranchControllerApi();
    const [agentList, setAgentList] = useState<Array<Agent>>();
    const [branchList, setBranchList] = useState<Array<Branch>>();
    const [pageState, setPageState] = useState(PageState.AGENT_LIST);
    const [agentFormType, setAgentFormType] = useState(AgentFormType.ADD);
    const [selectedUser, setSelectedUser] = useState<Agent>();
    const [agentListPageNumber, setAgentListPageNumber] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
    const [errorSnackbarState, setErrorSnackbarState] = useState<SnackbarState>({open: false});

    useEffect(() => {
        administratorControllerApi().then((api) =>
            api
                .getAgents()
                .then((response) => {
                    setAgentList(response.data.agents);
                    setShowLoader(false);
                })
                .catch((err) => {
                    setShowLoader(false);
                    if (ErrorType[err.response.data.type]) {
                        showErrorMessage(ErrorType[err.response.data.type]);
                        return;
                    }
                    showErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                })
        );
        branchControllerApi().then((api) =>
            api
                .getBranches()
                .then((response) => {
                    setBranchList(response.data.branches);
                    setShowLoader(false);
                })
                .catch((err) => {
                    setShowLoader(false);
                    if (ErrorType[err.response.data.type]) {
                        showErrorMessage(ErrorType[err.response.data.type]);
                        return;
                    }
                    showErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                })
        );
    }, []);

    const handleAddNewUserClick = () => {
        setSelectedUser(undefined);
        setAgentFormType(AgentFormType.ADD);
        setPageState(PageState.AGENT_FORM);
    };

    const handleEditUserClick = (agentData: Agent) => {
        setSelectedUser(agentData);
        setAgentFormType(AgentFormType.EDIT);
        setPageState(PageState.AGENT_FORM);
    };

    const handleAgentFormClose = () => {
        setPageState(PageState.AGENT_LIST);
    };

    const handleUserAdded = (newUser: Agent) => {
        setAgentList((prevState) => prevState && [...prevState, newUser]);
    };

    const handleUserEdited = (editedUser: Agent) => {
        setAgentList(agentList?.map((agent) => (agent.id === editedUser.id ? editedUser : agent)));
    };

    const handleAgentListPageNumberChange = (pageNumber: number) => {
        setAgentListPageNumber(pageNumber);
    };

    const showErrorMessage = (message: string) => {
        setErrorSnackbarState({
            open: true,
            message: message,
        });
        setTimeout(() => setErrorSnackbarState({open: false}), 4000);
    };

    return (
        <div className={styles.root}>
            <ErrorSnackbar open={errorSnackbarState.open} message={errorSnackbarState.message} />
            <AppBarHeader headerText={pageState === PageState.AGENT_LIST ? 'AGENTI' : 'DODAJ/UREDI AGENTA'} />
            <div style={{display: 'flex', height: 'calc(100vh - 64px)', overflow: 'hidden'}}>
                <Loader appear={showLoader} />
                <DrawerNavigation />
                {pageState === PageState.AGENT_LIST ? (
                    <div style={{overflow: 'auto', width: '100%'}}>
                        <AgentList
                            agentList={agentList}
                            branchList={branchList}
                            onAddNewUser={handleAddNewUserClick}
                            onEditUser={handleEditUserClick}
                            defaultPageNumber={agentListPageNumber}
                            onPageNumberChanged={handleAgentListPageNumberChange}
                        />
                    </div>
                ) : (
                    <div style={{overflow: 'auto', width: '100%'}}>
                        <AgentForm
                            type={agentFormType}
                            branchList={branchList}
                            existingUser={selectedUser}
                            onFormClose={handleAgentFormClose}
                            onUserAdded={handleUserAdded}
                            onUserEdited={handleUserEdited}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};

export default AdminPage;
