import {FC} from 'react';
import {createTheme, ThemeProvider} from '@mui/material';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Redirect from './components/redirect/index';
import AdminPage from './components/admin';
import AgentPage from './components/agent';
import OrderInfo from './components/orderinfo';
import AuthProvider from './Auth/AuthProvider';
import PrivateRoute from './Auth/PrivateRoute';
import Refresh from './components/refresh/refresh';
import Profile from './components/profile';
import Branches from './components/branches';

const theme = createTheme({
    typography: {
        fontFamily: 'Montserrat, sans-serif !important',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontWeight: '600',
                },
            },
        },
    },
    palette: {
        primary: {main: '#987c64'},
        secondary: {main: '#000'},
    },
});

const App: FC = () => {
    return (
        <ThemeProvider theme={theme}>
            <AuthProvider>
                <Router>
                    <Switch>
                        <Route exact path="/">
                            <Refresh />
                        </Route>
                        <Route path="/redirect">
                            <Redirect />
                        </Route>
                        <PrivateRoute path="/profile">
                            <Profile />
                        </PrivateRoute>
                        <PrivateRoute path="/admin" role={'admin'}>
                            <AdminPage />
                        </PrivateRoute>
                        <PrivateRoute path="/branches" role={'admin'}>
                            <Branches />
                        </PrivateRoute>
                        <PrivateRoute path="/agent" role={'agent'}>
                            <AgentPage />
                        </PrivateRoute>
                        <PrivateRoute path="/orderinfo" role={'agent'}>
                            <OrderInfo />
                        </PrivateRoute>
                    </Switch>
                </Router>
            </AuthProvider>
        </ThemeProvider>
    );
};

export default App;
