import {AdministratorControllerApi, BranchesControllerApi, Configuration} from 'pay-by-loan-administrator-api';
import useAuth from '../Auth/useAuth';

export function useAdministratorControllerApi(): () => Promise<AdministratorControllerApi> {
    const auth = useAuth();
    return async () => {
        try {
            const resolve = await auth.areTokensValid();
            const configuration: Configuration = new Configuration({accessToken: resolve.token, basePath: process.env.REACT_APP_BASE_URL});
            return new AdministratorControllerApi(configuration);
        } catch (e) {
            window.location.reload();
            return Promise.reject();
        }
    };
}

export function useBranchControllerApi(): () => Promise<BranchesControllerApi> {
    const auth = useAuth();
    return async () => {
        try {
            const resolve = await auth.areTokensValid();
            const configuration: Configuration = new Configuration({accessToken: resolve.token, basePath: process.env.REACT_APP_BASE_URL});
            return new BranchesControllerApi(configuration);
        } catch (e) {
            window.location.reload();
            return Promise.reject();
        }
    };
}
