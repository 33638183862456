import {useState} from 'react';
import Cookies from 'universal-cookie/es6';
import jwt_decode, {JwtPayload} from 'jwt-decode';
import {IToken} from '../lib/interfaces/interfaces';

export interface IUseAuthProvider {
    signIn: (token: IToken, username: string, roles: Array<string>, cb: () => void) => void;
    signOut: (cb?: () => void) => void;
    areTokensValid: () => Promise<{token?: string; roles: string[]}>;
    username: string | null;
    userRoles: string[];
    token: any;
}

type customJwtPayload = JwtPayload & {preferred_username: string; realm_access: {roles: Array<string>}};

const useAuthProvider = (): IUseAuthProvider => {
    const cookies = new Cookies();
    const clientId: string = process.env.REACT_APP_CLIENT_ID || '';
    const tokenLink: string = process.env.REACT_APP_ACCESS_URL || '';
    const logoutLink: string = process.env.REACT_APP_LOGOUT_URL || '';
    const redirectUrl: string = process.env.REACT_APP_REDIRECT_URL || '';

    const [username, setUsername] = useState<string | null>(null);
    const [userRoles, setUserRoles] = useState<string[]>([]);
    const [token, setToken] = useState<any>({});

    const signIn = (accessToken: IToken, _username: string, roles: Array<string>, cb?: () => void) => {
        let expiresDate = new Date(accessToken.expires);

        cookies.set('access_token_obj', accessToken, {expires: expiresDate});
        cookies.set('refresh_token', accessToken.refresh_token, {expires: new Date(Date.now() + accessToken.refresh_expires_in * 1000)});
        cookies.set('username', _username, {expires: expiresDate});
        cookies.set('roles', roles, {expires: expiresDate});

        setAll(accessToken, _username, roles);
        cb && cb();
    };

    const setAll = async (accessToken: IToken, _username: string, roles: Array<string>) => {
        setUsername(_username);
        setUserRoles(roles);
        setToken(accessToken);
    };

    const signOut = async (cb?: () => void) => {
        setUsername(null);
        cookies.remove('access_token_obj');
        cookies.remove('username');
        cookies.remove('roles');
        cb && cb();
        window.location.href = `${logoutLink}?redirect_uri=${redirectUrl}`;
    };

    // check if tokens exist and return them
    const areTokensValid = async (): Promise<{token?: string; roles: string[]}> => {
        // get from cookie
        let _token = cookies.get('access_token_obj');
        let _refreshToken = cookies.get('refresh_token');
        // return from cookie
        if (_token !== undefined) {
            let _username = cookies.get('username');
            let _roles = cookies.get('roles');
            setAll(_token, _username, _roles);
            return Promise.resolve({token: _token.access_token, roles: _roles});
        }
        // return from silent refresh (got new token from server)
        if (_token === undefined && _refreshToken !== undefined) {
            _token = await refreshToken(_refreshToken);
            if (Object.keys(_token).length > 0) {
                return Promise.resolve({token: _token.token.access_token, roles: _token.roles});
            }
            return Promise.reject(new Error('Invalid token'));
        }
        return Promise.reject(new Error('Invalid token'));
    };

    // silent refresh method
    const refreshToken = async (_token: string) => {
        const bodyData = new URLSearchParams({
            client_id: clientId,
            grant_type: 'refresh_token',
            refresh_token: _token,
        });
        const response = await fetch(`${tokenLink}`, {
            method: 'POST',
            body: bodyData,
        });
        if (response.status >= 200 && response.status < 300) {
            const responseJson = await response.json();
            responseJson.expires = new Date(Date.now() + responseJson.expires_in * 1000);
            const decoded: customJwtPayload = jwt_decode(responseJson.access_token);
            signIn(responseJson, decoded.preferred_username, decoded.realm_access.roles);
            return {token: responseJson, roles: decoded.realm_access.roles};
        }
        return {};
    };

    return {username, token, userRoles, signIn, signOut, areTokensValid};
};

export default useAuthProvider;
