import React, {useState} from 'react';
import {Grid, Step, StepLabel, Stepper} from '@mui/material';
import {IContact, ICustomerData, IDocumentData, IOrder} from '../../lib/interfaces/merchantLoanerInterfaces';
import IdCardScreen from './idCardScreen';
import ProductInfoScreen from './productInfoScreen';
import PhoneMailScreen from './phoneMailScreen';
import FinishScreen from './finishScreen';
import useAuth from '../../Auth/useAuth';
import axios from 'axios';
import Loader from '../loader';
import styles from './style.module.scss';

function getSteps() {
    return ['Informacije o proizvodima', 'Skeniranje dokumenta', 'E-mail/telefon', 'Zahtjev poslan'];
}

const RequestForm = ({pageStateSetting, addNewRequest}: {pageStateSetting?: (num: number) => void; addNewRequest: (request: IOrder) => void}) => {
    const auth = useAuth();
    const [amount, setAmount] = useState<number>();
    const [currency, setCurrency] = useState<string>();
    const [productId, setProductId] = useState<number>();
    const [contact, setContact] = useState<IContact>();
    const [customerData, setCustomerData] = useState<ICustomerData>();
    const [documentData, setDocumentData] = useState<IDocumentData>();
    const [activeStep, setActiveStep] = React.useState(0);
    const [showLoader, setShowLoader] = useState(false);
    const [isOrderSuccess, setIsOrderSuccess] = useState(true);
    const steps = getSteps();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const firstScreenChange = (data: {amount: string; currency: string; productId: number}) => {
        setAmount(parseFloat(data.amount));
        setCurrency(data.currency);
        setProductId(data.productId);
        handleNext();
    };

    const secondScreenChange = (data: {customerData: ICustomerData; documentData: IDocumentData}) => {
        setCustomerData(data.customerData);
        setDocumentData(data.documentData);
        handleNext();
    };

    const fourthScreenChange = (data) => {
        data.phone = {
            dialCode: '385',
            number: data.phoneNumber,
        };
        delete data.phoneNumber;

        setContact(data);

        setShowLoader(true);

        auth.areTokensValid()
            .then((resolve) => {
                axios
                    .post(
                        `${process.env.REACT_APP_MERCHANT_BASE_URL}/api/v1/orders`,
                        {
                            loanDetails: {
                                paymentProvider: 'Kent Bank d.o.o.',
                                amount: amount,
                                currency: currency,
                                productId: productId,
                            },
                            customerData: customerData,
                            documentData: documentData,
                            contact: data,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${resolve.token}`,
                            },
                        }
                    )
                    .then((response) => {
                        setShowLoader(false);
                        setIsOrderSuccess(true);
                        handleNext();
                        addNewRequest(response.data);
                    })
                    .catch(() => {
                        setShowLoader(false);
                        setIsOrderSuccess(false);
                        handleNext();
                    });
            })
            .catch(() => {
                window.location.reload();
            });
    };

    const cancelFunc = () => {
        if (pageStateSetting) pageStateSetting(0);
    };

    const renderingFunction = () => {
        switch (activeStep) {
            case 0:
                return <ProductInfoScreen submitForm={firstScreenChange} cancel={cancelFunc} amount={amount} />;
            case 1:
                return (
                    <IdCardScreen
                        customerData={customerData}
                        documentData={documentData}
                        onIdCardScanFinish={secondScreenChange}
                        onCancel={cancelFunc}
                        goBack={handleBack}
                    />
                );
            case 2:
                return <PhoneMailScreen contactData={contact} submitForm={fourthScreenChange} cancel={cancelFunc} goBack={handleBack} />;
            case 3:
                return <FinishScreen pageStateSetting={pageStateSetting} success={isOrderSuccess} />;
        }
    };

    return (
        <Grid container direction="column" justifyContent="center" className={styles.mainContainerComp}>
            <Loader appear={showLoader} />
            <Stepper activeStep={activeStep} alternativeLabel>
                {steps.map((label) => (
                    <Step key={label}>
                        <StepLabel classes={{labelContainer: styles.alternativeLabel}}>{label}</StepLabel>
                    </Step>
                ))}
            </Stepper>
            {renderingFunction()}
        </Grid>
    );
};

export default RequestForm;
