import {useEffect, useState} from 'react';
import ErrorSnackbar from '../errorSnackbar';
import DrawerNavigation from '../navigation/drawerNavigation';
import {SnackbarState} from '../../lib/interfaces/payByLoanAdministratorInterfaces';
import Loader from '../loader';
import {useBranchControllerApi} from '../../api/apis';
import BranchList from './branchList';
import BranchForm from './branchForm';
import {ErrorType} from '../../lib/constants/errorTypes';
import {Branch} from 'pay-by-loan-administrator-api';
import AppBarHeader from '../appBarHeader';
import styles from './style.module.scss';

enum PageState {
    BRANCH_LIST = 0,
    BRANCH_FORM = 1,
}

const Branches = () => {
    const branchControllerApi = useBranchControllerApi();
    const [branchList, setBranchList] = useState<Array<Branch>>();
    const [pageState, setPageState] = useState(PageState.BRANCH_LIST);
    const [branchListPageNumber, setBranchListPageNumber] = useState(0);
    const [showLoader, setShowLoader] = useState(true);
    const [errorSnackbarState, setErrorSnackbarState] = useState<SnackbarState>({open: false});

    useEffect(() => {
        branchControllerApi().then((api) =>
            api
                .getBranches()
                .then((response) => {
                    setBranchList(response.data.branches);
                    setShowLoader(false);
                })
                .catch((err) => {
                    setShowLoader(false);
                    if (ErrorType[err.response.data.type]) {
                        showErrorMessage(ErrorType[err.response.data.type]);
                        return;
                    }
                    showErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                })
        );
    }, []);

    const handleAddNewBranchClick = () => {
        setPageState(PageState.BRANCH_FORM);
    };

    const handleDeleteBranchClick = (branchId: string) => {
        branchControllerApi().then((api) =>
            api
                .deleteBranch(branchId)
                .then(() => {
                    setBranchList(branchList?.filter((branch) => branch.id !== branchId));
                })
                .catch((err) => {
                    if (ErrorType[err.response.data.type]) {
                        showErrorMessage(ErrorType[err.response.data.type]);
                        return;
                    }
                    showErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                })
        );
    };

    const handleBranchFormClose = () => {
        setPageState(PageState.BRANCH_LIST);
    };

    const handleBranchAdded = (newBranch: Branch) => {
        setBranchList((prevState) => prevState && [...prevState, newBranch]);
        setPageState(PageState.BRANCH_LIST);
    };

    const handleBranchListPageNumberChange = (pageNumber: number) => {
        setBranchListPageNumber(pageNumber);
    };

    const showErrorMessage = (message: string) => {
        setErrorSnackbarState({
            open: true,
            message: message,
        });
        setTimeout(() => setErrorSnackbarState({open: false}), 4000);
    };

    return (
        <div className={styles.root}>
            <ErrorSnackbar open={errorSnackbarState.open} message={errorSnackbarState.message} />
            <AppBarHeader headerText={pageState === PageState.BRANCH_LIST ? 'POSLOVNICE' : 'DODAJ POSLOVNICU'} />
            <div style={{display: 'flex', height: 'calc(100vh - 64px)', overflow: 'hidden'}}>
                <Loader appear={showLoader} />
                <DrawerNavigation />
                {pageState === PageState.BRANCH_LIST ? (
                    <div style={{overflow: 'auto', width: '100%'}}>
                        {branchList && (
                            <BranchList
                                branchList={branchList}
                                onAddNewBranch={handleAddNewBranchClick}
                                onDeleteBranch={handleDeleteBranchClick}
                                defaultPageNumber={branchListPageNumber}
                                onPageNumberChanged={handleBranchListPageNumberChange}
                            />
                        )}
                    </div>
                ) : (
                    <div style={{overflow: 'auto', width: '100%'}}>
                        <BranchForm onFormClose={handleBranchFormClose} onBranchAdded={handleBranchAdded} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Branches;
