import {FC, PropsWithChildren, useState} from 'react';
import {Button, Checkbox, Typography, FormControl, FormControlLabel, Grid, IconButton, InputAdornment, MenuItem, TextField} from '@mui/material';
import {AddCircle, Info, Search} from '@mui/icons-material';
import {DataGrid, GridColDef, GridToolbarContainer, GridToolbarExport} from '@mui/x-data-grid';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {DesktopDatePicker} from '@mui/x-date-pickers/DesktopDatePicker';
import dayjs, {Dayjs} from 'dayjs';
import Skeleton from '@mui/lab/Skeleton';
import moment from 'moment';
import jwt_decode, {JwtPayload} from 'jwt-decode';
import useAuth from '../../Auth/useAuth';
import {IOrder} from '../../lib/interfaces/merchantLoanerInterfaces';
import {Branch} from 'pay-by-loan-administrator-api';
import styles from './style.module.scss';

function CustomToolbar() {
    return (
        <GridToolbarContainer>
            <GridToolbarExport />
        </GridToolbarContainer>
    );
}

type customJwtPayload = JwtPayload & {
    preferred_username: string;
    realm_access: {roles: Array<string>};
};

type Props = {
    requestList: Array<IOrder> | undefined;
    branchList: Array<Branch> | undefined;
    pageStateSetting: (num: number) => void;
    showOrderInfo: (selectedOrder: IOrder) => void;
    dataPageState: number;
    setDataPageState: (pageNumber: number) => void;
};

const RequestList: FC<PropsWithChildren<Props>> = ({requestList, branchList, pageStateSetting, showOrderInfo, dataPageState, setDataPageState}) => {
    const auth = useAuth();
    const [startDate, setStartDate] = useState<Dayjs>();
    const [finishDate, setFinishDate] = useState<Dayjs>();
    const [amountFrom, setAmountFrom] = useState<number>();
    const [amountTo, setAmountTo] = useState<number>();
    const [status, setStatus] = useState('ALL');
    const [branch, setBranch] = useState('ALL');
    const [showAllRequests, setShowAll] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [pageSize, setPageSize] = useState(10);

    let decoded = jwt_decode<customJwtPayload>(auth.token.access_token);

    const handleAmountFromChange = (event) => {
        const amount = event.target.value;
        setAmountFrom(amount === '' ? undefined : amount);
    };

    const handleAmountToChange = (event) => {
        const amount = event.target.value;
        setAmountTo(amount === '' ? undefined : amount);
    };

    const handleStatusSelect = (event) => {
        setStatus(event.target.value);
    };

    const handleBranchSelect = (event) => {
        setBranch(event.target.value);
    };

    const editSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleStartDateChange = (date: Dayjs | null) => {
        setStartDate(dayjs(date).startOf('day'));
    };

    const handleFinishDateChange = (date: Dayjs | null) => {
        setFinishDate(dayjs(date).startOf('day'));
    };

    const columns: GridColDef[] = [
        {
            field: '',
            headerName: ' ',
            width: 66,
            filterable: false,
            renderCell: (params) => {
                return (
                    <strong>
                        <IconButton color="primary" component="span" onClick={() => showOrderInfo(params.row.data)}>
                            <Info />
                        </IconButton>
                    </strong>
                );
            },
        },
        {
            field: 'createdAt',
            headerName: 'Datum',
            width: 130,
            filterable: false,
            renderCell: (params) => {
                let date = new Date(params.row.createdAt);
                let formattedDate = moment(date).format('DD.MM.yyyy. HH:mm');
                return <>{formattedDate}</>;
            },
        },
        {field: 'firstName', headerName: 'Ime', width: 130},
        {field: 'lastName', headerName: 'Prezime', width: 130},
        {field: 'status', headerName: 'Status', width: 120},
        {field: 'agentFullName', headerName: 'Agent', width: 140},
        {
            field: 'branchId',
            headerName: 'Poslovnica',
            width: 200,
            hide: decoded.realm_access.roles.includes('agent'),
            renderCell: (params) => {
                return decoded.realm_access.roles.includes('admin')
                    ? branchList?.find((branch) => {
                          return branch.id === params.row.data.merchantData.branchId;
                      })?.name
                    : null;
            },
        },
        {field: 'personalNumber', headerName: 'OIB', width: 130},
        {field: 'amount', headerName: 'Iznos', width: 150},
    ];

    const columnsSkeleton: GridColDef[] = [
        {
            field: '',
            headerName: '',
            width: 66,
            renderCell: () => {
                return <></>;
            },
        },
        {
            field: 'createdAt',
            headerName: 'Datum',
            width: 130,
            renderCell: () => {
                return <Skeleton variant="text" width={100} height={20} animation="wave" />;
            },
        },
        {
            field: 'firstName',
            headerName: 'Ime',
            width: 130,
            renderCell: () => {
                return <Skeleton variant="text" width={100} height={20} animation="wave" />;
            },
        },
        {
            field: 'lastName',
            headerName: 'Prezime',
            width: 130,
            renderCell: () => {
                return <Skeleton variant="text" width={100} height={20} animation="wave" />;
            },
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 120,
            renderCell: () => {
                return <Skeleton variant="text" width={80} height={20} animation="wave" />;
            },
        },
        {
            field: 'agentFullName',
            headerName: 'Agent',
            width: 140,
            renderCell: () => {
                return <Skeleton variant="text" width={80} height={20} animation="wave" />;
            },
        },
        {
            field: 'branchId',
            headerName: 'Poslovnica',
            width: 200,
            hide: !decoded.realm_access.roles.includes('admin'),
            renderCell: () => {
                return <Skeleton variant="text" width={120} height={20} animation="wave" />;
            },
        },
        {
            field: 'personalNumber',
            headerName: 'OIB',
            width: 130,
            renderCell: () => {
                return <Skeleton variant="text" width={80} height={20} animation="wave" />;
            },
        },
        {
            field: 'amount',
            headerName: 'Iznos',
            width: 150,
            renderCell: () => {
                return <Skeleton variant="text" width={80} height={20} animation="wave" />;
            },
        },
    ];

    const getRows = () => {
        if (requestList === undefined) {
            return [...Array(10)].map((_, i) => {
                return {id: i.toString()};
            });
        }
        return requestList
            .filter((request: IOrder) => {
                return startDate === undefined || dayjs(request.createdAt) >= startDate;
            })
            .filter((request: IOrder) => {
                return finishDate === undefined || dayjs(request.createdAt) <= finishDate;
            })
            .filter((request: IOrder) => {
                return showAllRequests || decoded?.realm_access.roles.includes('admin') || decoded?.preferred_username === request.agentId;
            })
            .filter((request: IOrder) => {
                return amountFrom === undefined || request.loanDetails.amount >= amountFrom;
            })
            .filter((request: IOrder) => {
                return amountTo === undefined || request.loanDetails.amount <= amountTo;
            })
            .filter((request: IOrder) => {
                return status === 'ALL' || request.status === status;
            })
            .filter((request) => {
                return branch === 'ALL' || request.merchantData.branchId === branch;
            })
            .filter((request: IOrder) => {
                return (
                    request.customerData.firstName.toLowerCase().includes(searchTerm) ||
                    request.customerData.lastName.toLowerCase().includes(searchTerm) ||
                    request.customerData.personalNumber.toLowerCase().includes(searchTerm)
                );
            })
            .map((order: IOrder) => {
                return {
                    id: order.id,
                    agentFullName: order.agentFullName,
                    personalNumber: order.customerData.personalNumber,
                    amount: 'HRK ' + order.loanDetails.amount.toLocaleString(undefined, {minimumFractionDigits: 2}),
                    createdAt: order.createdAt,
                    status: order.status,
                    firstName: order.customerData.firstName,
                    lastName: order.customerData.lastName,
                    data: order,
                };
            });
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
    };

    return (
        <Grid container direction="column" justifyContent="center" className={styles.mainContainer}>
            <Grid container direction="row" alignItems="center" justifyContent="space-around" style={{flexWrap: 'wrap'}}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DesktopDatePicker
                        label="Od:"
                        inputFormat="DD.MM.YYYY"
                        value={startDate}
                        onChange={handleStartDateChange}
                        maxDate={finishDate}
                        className={styles.filter}
                        renderInput={(params) => <TextField {...params} />}
                    />
                    <DesktopDatePicker
                        label="Do:"
                        inputFormat="DD.MM.YYYY"
                        value={finishDate}
                        onChange={handleFinishDateChange}
                        minDate={startDate}
                        className={styles.filter}
                        renderInput={(params) => <TextField {...params} />}
                    />
                </LocalizationProvider>
                <TextField
                    name="start"
                    type="number"
                    label="Od:"
                    value={amountFrom}
                    onChange={handleAmountFromChange}
                    className={styles.filter}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">HRK</InputAdornment>,
                    }}
                />
                <TextField
                    name="finish"
                    type="number"
                    label="Do:"
                    value={amountTo}
                    onChange={handleAmountToChange}
                    className={styles.filter}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">HRK</InputAdornment>,
                    }}
                />
                <FormControl variant="outlined" className={styles.filter}>
                    <TextField select label="STATUS" defaultValue={status} name="status" onChange={handleStatusSelect}>
                        <MenuItem value="ALL">SVI</MenuItem>
                        <MenuItem value="INITIATED">INITIATED</MenuItem>
                        <MenuItem value="COMPLETED">COMPLETED</MenuItem>
                        <MenuItem value="REJECTED">REJECTED</MenuItem>
                    </TextField>
                </FormControl>
                {decoded.realm_access.roles.includes('admin') && (
                    <FormControl variant="outlined" className={styles.filter}>
                        <TextField label="POSLOVNICA" defaultValue={branch} name="branch" onChange={handleBranchSelect}>
                            <MenuItem value="ALL">SVE</MenuItem>
                            {branchList?.map((branch: Branch, i: number) => (
                                <MenuItem value={branch.id} key={i}>
                                    {branch.name}
                                </MenuItem>
                            ))}
                        </TextField>
                    </FormControl>
                )}
                <TextField
                    name="search"
                    onChange={editSearchTerm}
                    type="text"
                    label="Pretraži:"
                    value={searchTerm}
                    className={styles.filter}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color="primary" />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" style={{marginTop: '2rem'}}>
                {!decoded?.realm_access.roles.includes('admin') && (
                    <Grid container direction="row" alignItems="center" style={{width: 'fit-content'}}>
                        <Button
                            color="primary"
                            style={{
                                textTransform: 'none',
                                borderRadius: '0.55rem',
                            }}
                            onClick={() => pageStateSetting(1)}>
                            <AddCircle />
                            <Typography
                                variant="body1"
                                style={{
                                    color: '#000',
                                    marginLeft: '0.5rem',
                                }}>
                                Dodaj novi zahtjev
                            </Typography>
                        </Button>
                    </Grid>
                )}
                {decoded?.realm_access.roles.includes('agent') && (
                    <FormControlLabel
                        control={<Checkbox checked={showAllRequests} onChange={() => setShowAll(!showAllRequests)} name="checkedA" />}
                        label="Prikaži zahtjeve svih agenata"
                    />
                )}
            </Grid>
            <div style={{width: '100%'}}>
                <DataGrid
                    autoHeight
                    disableSelectionOnClick
                    disableColumnMenu
                    disableDensitySelector
                    disableColumnSelector
                    page={dataPageState}
                    onPageChange={(newPageNumber: number) => {
                        setDataPageState(newPageNumber);
                    }}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    pagination
                    rows={getRows()}
                    columns={requestList !== undefined ? columns : columnsSkeleton}
                    components={{
                        Toolbar: CustomToolbar,
                    }}
                />
            </div>
        </Grid>
    );
};

export default RequestList;
