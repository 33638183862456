import {useContext} from 'react';
import {AuthContext} from './AuthProvider';
import {IUseAuthProvider} from './useAuthProvider';

export enum Roles {
    offline = 'offline_access',
    admin = 'admin',
    agent = 'agent',
    umaAuth = 'uma_authorization',
}

export const getLandingPageByRole = (roles: string[]): string => {
    if (roles.includes(Roles.admin)) {
        return '/admin';
    }
    if (roles.includes(Roles.agent)) {
        return '/agent';
    }
    return '/no-privileges';
};

const useAuth = (): IUseAuthProvider => useContext(AuthContext);

export default useAuth;
