import {useEffect, useState} from 'react';
import {Route, RouteProps, useHistory} from 'react-router-dom';
import useAuth, {getLandingPageByRole} from './useAuth';
import Loader from './../components/loader';
import {Grid} from '@mui/material';
import styles from './style.module.scss';

interface IPrivateRouteProps extends RouteProps {
    role?: string;
}

const PrivateRoute: React.FunctionComponent<IPrivateRouteProps> = ({children, path, role, ...rest}) => {
    const auth = useAuth();
    const history = useHistory();
    const [showLoader, setShowLoader] = useState<boolean>(true);

    useEffect(() => {
        auth.areTokensValid()
            .then((res) => {
                if (role) {
                    if (!res.roles.includes(role)) {
                        let landingPage = getLandingPageByRole(res.roles);
                        history.replace(landingPage);
                        return;
                    }
                }
                setShowLoader(false);
            })
            .catch(() => {
                let searchParams = new URLSearchParams();
                let _path = path ? path : '';
                searchParams.append('[private route] resume_url', _path.toString());
                history.replace(`/?${searchParams.toString()}`);
            });
    }, []);

    return (
        <>
            {showLoader && (
                <Grid container direction="column" alignItems="center" justifyContent="center" className={styles.mainDiv}>
                    <Loader appear={showLoader} />
                </Grid>
            )}
            {!showLoader && <Route {...rest}>{children}</Route>}
        </>
    );
};

export default PrivateRoute;
