import {PropsWithChildren, FC, useState} from 'react';
import {Grid, Button, FormGroup, TextField, ButtonGroup} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import ErrorSnackbar from '../errorSnackbar';
import {ErrorType} from '../../lib/constants/errorTypes';
import {SnackbarState} from '../../lib/interfaces/payByLoanAdministratorInterfaces';
import {useBranchControllerApi} from '../../api/apis';
import {Branch} from 'pay-by-loan-administrator-api';
import styles from './style.module.scss';

type Props = {
    onFormClose: () => void;
    onBranchAdded: (branch: Branch) => void;
};

const BranchForm: FC<PropsWithChildren<Props>> = ({onFormClose, onBranchAdded}) => {
    const branchControllerApi = useBranchControllerApi();
    const {control, handleSubmit} = useForm();
    const [errorSnackbarState, setErrorSnackbarState] = useState<SnackbarState>({open: false});

    const submitForm = (data: Branch) => {
        branchControllerApi().then((api) =>
            api
                .createBranch(data)
                .then((response) => {
                    onBranchAdded(response.data);
                })
                .catch((err) => {
                    if (ErrorType[err.response.data.type]) {
                        showErrorMessage(ErrorType[err.response.data.type]);
                        return;
                    }
                    showErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                })
        );
    };

    const showErrorMessage = (message: string) => {
        setErrorSnackbarState({
            open: true,
            message: message,
        });
        setTimeout(() => setErrorSnackbarState({open: false}), 4000);
    };

    return (
        <Grid container direction="column" justifyContent="center" className={styles.mainContainer}>
            <ErrorSnackbar open={errorSnackbarState.open} message={errorSnackbarState.message} />
            <FormGroup>
                <Controller
                    name="name"
                    control={control}
                    render={({field: {onChange, value}, fieldState: {error}}) => (
                        <TextField
                            className={styles.inputMargin}
                            label="IME"
                            variant="outlined"
                            value={value ? value : ''}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{
                        required: 'Polje ne može biti prazno.',
                    }}
                />
                <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="contained primary button group">
                    <Button onClick={handleSubmit(submitForm)}>Spremi</Button>
                    <Button onClick={onFormClose}>Odustani</Button>
                </ButtonGroup>
            </FormGroup>
        </Grid>
    );
};

export default BranchForm;
