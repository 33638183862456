import useAuth from '../../Auth/useAuth';
import {useHistory} from 'react-router-dom';
import jwtDecode, {JwtPayload} from 'jwt-decode';
import {AppBar, Button, Toolbar, IconButton, Avatar, Typography} from '@mui/material';
import {FC, PropsWithChildren} from 'react';
import styles from './style.module.scss';

type customJwtPayload = JwtPayload & {given_name: string; family_name: string; name: string};

type Props = {
    headerText: string;
};

const AppBarHeader: FC<PropsWithChildren<Props>> = ({headerText}) => {
    const auth = useAuth();
    const history = useHistory();

    const decoded: customJwtPayload = jwtDecode(auth.token.access_token);

    const handleProfileClick = () => {
        history.push(`/profile`);
    };

    console.log(decoded);

    return (
        <AppBar position="static" color="secondary">
            <Toolbar>
                <div className={styles.userName}>
                    <IconButton component="span" onClick={handleProfileClick}>
                        <Avatar>
                            {decoded.given_name && decoded?.given_name[0]}
                            {decoded.family_name && decoded?.family_name[0]}
                        </Avatar>
                        <Typography variant="h6" style={{marginLeft: '0.5rem', color: 'white'}}>
                            {decoded?.given_name + ' ' + decoded?.family_name}
                        </Typography>
                    </IconButton>
                </div>
                <Typography variant="h6" className={`${styles.flexGrow} ${styles.titles}`}>
                    {headerText}
                </Typography>
                <Button color="inherit" className={styles.flexGrow} style={{textAlign: 'end', display: 'block'}} onClick={() => auth.signOut()}>
                    Odjava
                </Button>
            </Toolbar>
        </AppBar>
    );
};

export default AppBarHeader;
