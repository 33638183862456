import {useEffect, useState} from 'react';
import {NavLink, useHistory} from 'react-router-dom';
import useAuth, {Roles} from '../../Auth/useAuth';
import {Drawer, Divider, ListItemText} from '@mui/material';
import styles from './style.module.scss';

const DrawerNavigation = () => {
    const auth = useAuth();
    const history = useHistory();
    const routesBlackList = ['/', '/login', '/refresh'];

    const [showNav, setShowNav] = useState<boolean>(false);

    useEffect(() => {
        history.listen((location) => {
            setShowNav(!routesBlackList.includes(location.pathname));
        });
        setShowNav(!routesBlackList.includes(history.location.pathname));
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const showLinkByRole = (role: string): boolean => {
        return auth.userRoles.includes(role);
    };

    return (
        <>
            {showNav && (
                <div className={styles.root}>
                    <Drawer
                        className={styles.drawer}
                        variant="permanent"
                        classes={{
                            paper: styles.drawerPaper,
                        }}
                        anchor="left">
                        <Divider />
                        {
                            <div className={styles.navList}>
                                {showLinkByRole(Roles.admin) && (
                                    <NavLink className={styles.navLink} to="/admin">
                                        <ListItemText primary="Agenti" />
                                    </NavLink>
                                )}
                                <NavLink className={styles.navLink} to="/agent">
                                    <ListItemText primary="Zahtjevi" />
                                </NavLink>
                                {showLinkByRole(Roles.admin) && (
                                    <NavLink className={styles.navLink} to="/branches">
                                        <ListItemText primary="Poslovnice" />
                                    </NavLink>
                                )}
                            </div>
                        }
                        <Divider />
                    </Drawer>
                </div>
            )}
        </>
    );
};

export default DrawerNavigation;
