import {TextField, Button, ButtonGroup, FormGroup, InputAdornment, FormLabel} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import styles from './style.module.scss';

const PhoneMailScreen = ({submitForm, goBack, cancel, contactData}) => {
    const {control, handleSubmit} = useForm();

    return (
        <FormGroup>
            <FormLabel component="legend" style={{marginTop: '1rem', alignSelf: 'center'}}>
                UNESITE BROJ TELEFONA I E-MAIL ADRESU
            </FormLabel>
            <Controller
                name="phoneNumber"
                control={control}
                defaultValue={contactData?.phone.number}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                        className={styles.inputMarginComp}
                        label="BROJ TELEFONA"
                        type="number"
                        variant="outlined"
                        value={value ?? ''}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message || null}
                        placeholder="981234567"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start" style={{marginRight: 0}}>
                                    +385
                                </InputAdornment>
                            ),
                        }}
                    />
                )}
                rules={{
                    required: 'Potrebno unijeti broj mobitela.',
                    pattern: {
                        value: /^0?(91|92|95|97|98|99)[0-9]{6,8}$/,
                        message: 'Neispravan broj mobitela.',
                    },
                }}
            />
            <Controller
                name="email"
                control={control}
                defaultValue={contactData?.email}
                render={({field: {onChange, value}, fieldState: {error}}) => (
                    <TextField
                        className={`${styles.inputMarginComp} e-mail`}
                        label="E-MAIL ADRESA"
                        type="text"
                        variant="outlined"
                        value={value ?? ''}
                        onChange={onChange}
                        error={!!error}
                        helperText={error?.message || null}
                        style={{marginBottom: '3rem'}}
                        placeholder="example@domain.com"
                    />
                )}
                rules={{
                    required: 'Potrebno unijeti e-mail adresu.',
                    pattern: {
                        value: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                        message: 'Neispravan email.',
                    },
                }}
            />
            <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="contained primary button group">
                <Button onClick={handleSubmit(submitForm)}>Kreiraj zahtjev</Button>
                <Button onClick={goBack}>Natrag</Button>
                <Button onClick={cancel}>Odustani</Button>
            </ButtonGroup>
        </FormGroup>
    );
};

export default PhoneMailScreen;
