import {useEffect, useState} from 'react';
import {Avatar, AppBar, Toolbar, Typography, Button, IconButton} from '@mui/material';
import axios from 'axios';
import ErrorSnackbar from '../errorSnackbar';
import RequestList from './requestList';
import RequestForm from '../requestform';
import {ArrowBack} from '@mui/icons-material';
import OrderInfo from '../orderinfo';
import useAuth from '../../Auth/useAuth';
import {IOrder} from '../../lib/interfaces/merchantLoanerInterfaces';
import {useHistory} from 'react-router-dom';
import jwt_decode, {JwtPayload} from 'jwt-decode';
import DrawerNavigation from '../navigation/drawerNavigation';
import {ErrorType} from '../../lib/constants/errorTypes';
import {SnackbarState} from '../../lib/interfaces/payByLoanAdministratorInterfaces';
import {useBranchControllerApi} from '../../api/apis';
import {Branch} from 'pay-by-loan-administrator-api';
import styles from './style.module.scss';

type customJwtPayload = JwtPayload & {given_name: string; family_name: string; name: string; realm_access: {roles: Array<string>}};

enum PageState {
    REQUEST_LIST = 0,
    REQUEST_FORM = 1,
    REQUEST_INFO = 2,
}

const AgentPage = () => {
    const auth = useAuth();
    const branchControllerApi = useBranchControllerApi();
    const history = useHistory();
    const [pageState, setPageState] = useState(PageState.REQUEST_LIST);
    const [requestList, setRequestList] = useState<Array<IOrder>>();
    const [branchList, setBranchList] = useState<Array<Branch>>();
    const [order, setOrder] = useState<IOrder>();
    const [dataPageState, setDataPageState] = useState(0);
    const [errorSnackbarState, setErrorSnackbarState] = useState<SnackbarState>({open: false});

    let decoded: customJwtPayload = jwt_decode(auth.token.access_token);

    useEffect(() => {
        if (!requestList && pageState === 0) {
            auth.areTokensValid()
                .then((resolve) => {
                    axios
                        .get(`${process.env.REACT_APP_MERCHANT_BASE_URL}/api/v1/orders`, {
                            headers: {
                                Authorization: `Bearer ${resolve.token}`,
                            },
                        })
                        .then((response) => {
                            setRequestList(response.data.orders);
                        })
                        .catch((err) => {
                            if (ErrorType[err.response.data.type]) {
                                showErrorMessage(ErrorType[err.response.data.type]);
                                return;
                            }
                            showErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                        });
                })
                .catch(() => {
                    window.location.reload();
                });
            if (decoded?.realm_access.roles.includes('admin')) {
                branchControllerApi().then((api) =>
                    api
                        .getBranches()
                        .then((response) => {
                            setBranchList(response.data.branches);
                        })
                        .catch((err) => {
                            if (ErrorType[err.response.data.type]) {
                                showErrorMessage(ErrorType[err.response.data.type]);
                                return;
                            }
                            showErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                        })
                );
            }
        }
    }, [pageState]);

    const addNewRequest = (request: IOrder) => {
        setRequestList((prevState) => prevState && [...prevState, request]);
    };

    const pageStateFunc = (num: number) => {
        setPageState(num);
    };

    const orderIdFunc = (selectedOrder: IOrder) => {
        setOrder(selectedOrder);
        setPageState(PageState.REQUEST_INFO);
    };

    const handleProfileClick = () => {
        history.push(`/profile`);
    };

    const renderingFunction = () => {
        if (pageState === PageState.REQUEST_LIST) {
            return (
                <RequestList
                    requestList={requestList}
                    branchList={branchList}
                    pageStateSetting={pageStateFunc}
                    showOrderInfo={orderIdFunc}
                    dataPageState={dataPageState}
                    setDataPageState={setDataPageState}
                />
            );
        }
        if (pageState === PageState.REQUEST_FORM) {
            return <RequestForm pageStateSetting={pageStateFunc} addNewRequest={addNewRequest} />;
        }
        return <OrderInfo orderDetails={order} branchList={branchList} />;
    };

    const getTitle = () => {
        if (pageState === PageState.REQUEST_LIST) {
            return 'ZAHTJEVI';
        }

        if (pageState === PageState.REQUEST_FORM) {
            return 'NOVI ZAHTJEV';
        }

        return 'DETALJI ZAHTJEVA';
    };

    const showErrorMessage = (message: string) => {
        setErrorSnackbarState({
            open: true,
            message: message,
        });
        setTimeout(() => setErrorSnackbarState({open: false}), 4000);
    };

    return (
        <div className={styles.root}>
            <ErrorSnackbar open={errorSnackbarState.open} message={errorSnackbarState.message} />
            <AppBar position="static" color="secondary">
                <Toolbar className={`${styles.toolbar}`}>
                    <div className={styles.userName}>
                        {pageState !== PageState.REQUEST_LIST && (
                            <IconButton
                                onClick={() => {
                                    setPageState(PageState.REQUEST_LIST);
                                }}
                                style={{marginRight: '1rem'}}>
                                <ArrowBack htmlColor="white" />
                            </IconButton>
                        )}
                        <IconButton component="span" onClick={handleProfileClick}>
                            <Avatar>
                                {decoded?.given_name[0] ?? ''}
                                {decoded?.family_name[0] ?? ''}
                            </Avatar>
                            <Typography variant="h6" style={{marginLeft: '0.5rem', color: 'white'}}>
                                {decoded?.given_name + ' ' + decoded?.family_name}
                            </Typography>
                        </IconButton>
                    </div>
                    <Typography variant="h6" className={`${styles.titles}`}>
                        {getTitle()}
                    </Typography>
                    <Button color="inherit" style={{textAlign: 'end', display: 'block'}} onClick={() => auth.signOut()}>
                        Odjava
                    </Button>
                </Toolbar>
            </AppBar>
            <div style={{display: 'flex', height: 'calc(100vh - 64px)', overflow: 'hidden'}}>
                {decoded?.realm_access.roles.includes('admin') && <DrawerNavigation />}
                <div style={{overflow: 'auto', width: '100%'}}>{renderingFunction()}</div>
            </div>
        </div>
    );
};

export default AgentPage;
