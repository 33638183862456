import {forwardRef, ForwardRefRenderFunction, PropsWithChildren, useEffect, useImperativeHandle, useRef} from 'react';
import styles from './style.module.scss';

interface ICaptureData {
    blob: Blob | null;
    pixelData: ImageData;
}

interface IFrameQuality {
    frame: ICaptureData;
    quality: number;
}

interface ICameraComponentProps {
    mirrored?: boolean;
    videoConstraints?: MediaStreamConstraints['video'];
    captureFps: number;
}

export interface ICameraComponent {
    startCapturing(): void;
    getScreenshot(): Promise<string | null>;
    stopCamera(): void;
}

const CameraComponentComp: ForwardRefRenderFunction<ICameraComponent, PropsWithChildren<ICameraComponentProps>> = (
    {mirrored = false, videoConstraints, captureFps},
    fromRef
) => {
    const videoEl = useRef<HTMLVideoElement>(null);

    let scanFrameQueue: IFrameQuality[] = [];
    let capturingInterval;

    useEffect(() => {
        startBestCamera();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getScreenshot = async () => {
        clearInterval(capturingInterval);
        let _frame = getBestFrame();
        startCapturing();
        return _frame;
    };

    const captureAndQueue = async () => {
        let _frame: ICaptureData = await captureFrame();
        queueFrame(_frame);
    };

    const startCapturing = async () => {
        captureAndQueue();
        capturingInterval = setInterval(() => {
            captureAndQueue();
        }, 1000 / captureFps);
    };

    const startBestCamera = async () => {
        try {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({
                    audio: false,
                    video: true,
                });
                stream.getTracks().forEach((track) => track.stop());
            } catch (err) {
                console.error('best camera error', err);
                return;
            }

            const devices = await navigator.mediaDevices.enumerateDevices();
            let videoDevicesTmp: MediaDeviceInfo[] = [];
            devices.forEach((device) => {
                if (device.kind === 'videoinput' && !device.label.toLowerCase().includes('virtual')) {
                    videoDevicesTmp.push(device);
                }
            });

            let videoDevices: MediaDeviceInfo[] = [];
            videoDevicesTmp.forEach((device) => {
                if (!isBackCamera(device.label)) {
                    videoDevices.push(device);
                }
            });
            videoDevicesTmp.forEach((device) => {
                if (isBackCamera(device.label)) {
                    videoDevices.push(device);
                }
            });

            let error;
            for (let i = videoDevices.length - 1; i >= 0; i--) {
                const device = videoDevices[i];

                try {
                    const stream = await navigator.mediaDevices.getUserMedia({
                        audio: false,
                        video: {
                            deviceId: {exact: device.deviceId},
                            width: {min: 640, ideal: 1280},
                            height: {min: 480, ideal: 720},
                            facingMode: {ideal: 'environment'},
                        },
                    });

                    cameraFound(stream);
                    return;
                } catch (err) {
                    console.error('starting camera error', err);
                    error = err;
                }
            }

            if (error !== undefined) {
                console.error('some error', error);
            }
        } catch (err) {
            console.error('error', err);
        }
    };

    const cameraFound = (stream) => {
        if (!stream || !videoEl.current) {
            return;
        }
        videoEl.current.srcObject = stream;
        videoEl.current.play().catch((err) => {
            console.error('error on play', err);
        });
    };

    const isBackCamera = (label) => {
        if (!label) {
            return false;
        }
        const backCameraKeywords = [
            'rear',
            'back',
            'rück',
            'arrière',
            'trasera',
            'trás',
            'traseira',
            'posteriore',
            '后面',
            '後面',
            '背面',
            '后置', // alternative
            '後置', // alternative
            '背置', // alternative
            'задней',
            'الخلفية',
            '후',
            'arka',
            'achterzijde',
            'หลัง',
            'baksidan',
            'bagside',
            'sau',
            'bak',
            'tylny',
            'takakamera',
            'belakang',
            'אחורית',
            'πίσω',
            'spate',
            'hátsó',
            'zadní',
            'darrere',
            'zadná',
            'задня',
            'stražnja',
            'belakang',
            'बैक',
        ];

        const lowercaseLabel = label.toLowerCase();

        return backCameraKeywords.some((keyword) => {
            return lowercaseLabel.includes(keyword);
        });
    };

    const captureFrame = async (): Promise<ICaptureData> => {
        try {
            let canvas = document.createElement('canvas');
            canvas.width = videoEl.current!.videoWidth;
            canvas.height = videoEl.current!.videoHeight;
            canvas.getContext('2d')!.drawImage(videoEl.current!, 0, 0, canvas.width, canvas.height);
            let pixelData = canvas.getContext('2d')!.getImageData(0, 0, canvas.width, canvas.height);
            return new Promise((resolve) => {
                if (canvas.toBlob) {
                    canvas.toBlob((blob) => resolve({blob, pixelData}), 'image/jpeg', 0.95);
                }
                let binStr = atob(canvas.toDataURL('image/jpeg', 0.95).split(',')[1]),
                    len = binStr.length,
                    arr = new Uint8Array(len);
                Array.prototype.forEach.call(arr, (_, index) => (arr[index] = binStr.charCodeAt(index)));
                let blob = new Blob([arr], {type: 'image/jpeg'});
                resolve({blob, pixelData});
            });
        } catch (err) {
            console.error('error on canvas draw', err);
            clearInterval(capturingInterval);
            return Promise.reject('Error on canvas draw');
        }
    };

    const queueFrame = (scanInputFrame: ICaptureData) => {
        let frameQuality: number = getFrameQuality(scanInputFrame.pixelData);
        scanFrameQueue.push({
            frame: scanInputFrame,
            quality: frameQuality,
        });
    };

    const getBestFrame = async () => {
        try {
            if (scanFrameQueue.length > 0) {
                let bestQuality = 0;
                let bestFrame;
                scanFrameQueue.forEach((scanFrame) => {
                    if (scanFrame.quality > bestQuality) {
                        bestQuality = scanFrame.quality;
                        bestFrame = scanFrame.frame;
                    }
                });
                scanFrameQueue = [];
                if (bestFrame !== undefined) {
                    //provjera sa arrayem requestova
                    let _result: string | void = await blobToBase64String(bestFrame.blob);
                    return _result ? _result : null;
                }
                return null;
            }
            let _frame: ICaptureData = await captureFrame();
            let _result = await blobToBase64String(_frame.blob);
            return _result ? _result : null;
        } catch (err) {
            console.error(err);
            return null;
        }
    };

    const blobToBase64String = (blob) => {
        return blobToBinaryString(blob)
            .then((data) => {
                return btoa(data as string);
            })
            .catch((err) => {
                console.error('blobToBase64String error', err);
            });
    };

    const blobToBinaryString = (blob) => {
        let promise = new Promise(function (resolve, reject) {
            var reader = new FileReader();
            var hasBinaryString = typeof reader.readAsBinaryString === 'function';
            reader.onloadend = function () {
                var result = reader.result || '';
                if (hasBinaryString) {
                    return resolve(result);
                }
                resolve(arrayBufferToBinaryString(result));
            };
            reader.onerror = reject;
            if (hasBinaryString) {
                reader.readAsBinaryString(blob);
                return;
            }
            reader.readAsArrayBuffer(blob);
        });
        return promise
            .then((result) => {
                return result;
            })
            .catch((err) => {
                console.error('error', err);
            });
    };

    const arrayBufferToBinaryString = (buffer) => {
        var binary = '';
        var bytes = new Uint8Array(buffer);
        var length = bytes.byteLength;
        var i = -1;
        while (++i < length) {
            binary += String.fromCharCode(bytes[i]);
        }
        return binary;
    };

    const getFrameQuality = (pixelData: ImageData): number => {
        return calculateFrameQuality(pixelData.data, pixelData.width, pixelData.height);
    };

    const calculateFrameQuality = (rgbaImgData: Uint8ClampedArray, width: number, height: number): number => {
        var vertScanLineNum = 28;
        var horizScanLineNum = 20;
        var totalStrength = 0;
        var sampleNum = 0;
        for (let i = 0; i < vertScanLineNum; i++) {
            let distance = parseInt((width / (vertScanLineNum + 1)).toString(), 10);
            let col = parseInt((distance * i + distance / 2).toString(), 10);
            for (let row = 1; row < height - 1; row++) {
                let curPixel = getIntensity(rgbaImgData, row, col, width);
                let prevPixel = getIntensity(rgbaImgData, row - 1, col, width);
                let nextPixel = getIntensity(rgbaImgData, row + 1, col, width);
                let lastDiff = prevPixel - curPixel;
                let currDiff = curPixel - nextPixel;
                let secondDiff = currDiff - lastDiff;
                sampleNum += 1;
                totalStrength += secondDiff * secondDiff;
            }
        }
        for (let i = 0; i < horizScanLineNum; i++) {
            let distance = parseInt((height / (horizScanLineNum + 1)).toString(), 10);
            let row = parseInt((distance * i + distance / 2).toString(), 10);
            for (let col = 1; col < width - 1; col++) {
                let curPixel = getIntensity(rgbaImgData, row, col, width);
                let prevPixel = getIntensity(rgbaImgData, row, col - 1, width);
                let nextPixel = getIntensity(rgbaImgData, row, col + 1, width);
                let lastDiff = prevPixel - curPixel;
                let currDiff = curPixel - nextPixel;
                let secondDiff = currDiff - lastDiff;
                sampleNum += 1;
                totalStrength += secondDiff * secondDiff;
            }
        }
        var res = totalStrength / sampleNum;
        var qratio = parseFloat((width * height).toString()) / (640.0 * 480.0);
        if (qratio > 1.0) {
            if (qratio > 10.0) qratio = 10.0;
            res /= qratio;
            return res;
        }
        res *= qratio;
        return res;
    };

    const getIntensity = (rgbaImgData, row, col, width) => {
        var baseIdx = (row * width + col) * 4;
        var r = rgbaImgData[baseIdx];
        var g = rgbaImgData[baseIdx + 1];
        var b = rgbaImgData[baseIdx + 2];
        return 0.2126 * r + 0.7152 * g + 0.0722 * b;
    };

    const stopCamera = () => {
        clearInterval(capturingInterval);
        scanFrameQueue = [];
        try {
            videoEl.current!.pause();
            let srcObj: MediaStream = videoEl.current!.srcObject as MediaStream;
            srcObj.getTracks()[0].stop();
            videoEl.current!.srcObject = null;
            videoEl.current!.load();
        } catch (err) {
            console.error('error on camera stop', err);
        }
    };

    useImperativeHandle(fromRef, () => ({
        startCapturing,
        getScreenshot,
        stopCamera,
    }));

    return <video ref={videoEl} className={mirrored ? styles.videoCompMirrored : styles.videoComponent} autoPlay muted playsInline></video>;
};

const CameraComponent = forwardRef(CameraComponentComp);
export default CameraComponent;
