import {useState} from 'react';
import {Grid} from '@mui/material';
import {RequestAuthorizationCode} from 'react-oauth2-auth-code-flow';
import ClientOAuth2 from 'client-oauth2';
import styles from './style.module.scss';

const oauthClient = new ClientOAuth2({
    clientId: process.env.REACT_APP_CLIENT_ID,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
    accessTokenUri: process.env.REACT_APP_ACCESS_URL,
    authorizationUri: process.env.REACT_APP_AUTH_URL,
    redirectUri: `${process.env.REACT_APP_REDIRECT_URL}/redirect`,
    scopes: [],
});

interface IRefreshProps {}

const Refresh: React.FunctionComponent<IRefreshProps> = (props) => {
    const searchParams = new URLSearchParams(window.location.search);
    let _url = searchParams.get('resume_url');
    _url = _url !== null ? _url : '';
    const resumeUrl = useState<string>(_url as string);
    const state = {};
    if (_url !== '') {
        state['resumeurl'] = resumeUrl;
    }

    const logIn = (url: string): string => {
        window.location.href = url;
        return 'Refreshing';
    };

    return (
        <Grid container direction="column" alignItems="center" className={styles.root}>
            <RequestAuthorizationCode
                oauthClient={oauthClient}
                state={state}
                render={({url}) => {
                    <div>
                        {logIn(url)} {'Refreshing'}
                    </div>;
                    return null;
                }}
            />
            <div className={`${styles.ilustration} il il-shield`} />
        </Grid>
    );
};

export default Refresh;
