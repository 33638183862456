import {MouseEvent, useState} from 'react';
import {
    AppBar,
    Avatar,
    Button,
    ButtonGroup,
    CircularProgress,
    FormGroup,
    Grid,
    IconButton,
    InputAdornment,
    Snackbar,
    TextField,
    Toolbar,
    Typography,
} from '@mui/material';
import ErrorSnackbar from '../errorSnackbar';
import useAuth from '../../Auth/useAuth';
import {useHistory} from 'react-router-dom';
import {useForm} from 'react-hook-form';
import {ErrorType} from '../../lib/constants/errorTypes';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import jwt_decode, {JwtPayload} from 'jwt-decode';
import {useAdministratorControllerApi} from '../../api/apis';
import styles from './style.module.scss';

interface IProfileProps {}

type customJwtPayload = JwtPayload & {name: string};

const Profile: React.FunctionComponent<IProfileProps> = (props) => {
    const auth = useAuth();
    const administratorControllerApi = useAdministratorControllerApi();
    const history = useHistory();
    const [spyEye, setSpyEye] = useState<{newPassword: boolean; repeatNewPassword: boolean}>({
        newPassword: false,
        repeatNewPassword: false,
    });
    const [openError, setOpenError] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const {
        register,
        formState: {errors},
        getValues,
        handleSubmit,
    } = useForm();
    const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
    const [sending, setSending] = useState<boolean>(false);

    let decoded: customJwtPayload = jwt_decode(auth.token.access_token);

    const submitForm = (data, e) => {
        setSending(true);

        administratorControllerApi().then((api) =>
            api
                .changePassword({
                    newPassword: data.newPassword,
                })
                .then(() => {
                    setSending(false);
                    setOpenSnackbar(true);
                    e.target.reset();
                })
                .catch((err) => {
                    e.target.reset();
                    setSending(false);
                    if (ErrorType[err.response.data.type]) {
                        showErrorMessage(ErrorType[err.response.data.type]);
                        return;
                    }

                    showErrorMessage('Greška. Pokušajte ponovno.');
                })
        );
    };

    const showErrorMessage = (message: string) => {
        setErrorMessage(message);
        setOpenError(true);
        setTimeout(() => setOpenError(false), 4000);
    };

    const handleBack = () => {
        auth?.userRoles.includes('admin') ? history.push('/admin') : history.push('/agent');
    };

    return (
        <div className={styles.root}>
            <AppBar position="static" color="secondary">
                <Toolbar>
                    <div className={styles.userName}>
                        <IconButton component="span" onClick={handleBack}>
                            <Avatar>{decoded?.name[0] + decoded?.name[decoded?.name.indexOf(' ') + 1]}</Avatar>
                            <Typography variant="h6" style={{marginLeft: '0.5rem', color: 'white'}}>
                                {decoded?.name}
                            </Typography>
                        </IconButton>
                    </div>
                    <Typography variant="h6" className={`${styles.flexGrow} ${styles.titles}`}>
                        {'UREDI PROFIL'}
                    </Typography>
                    <Button color="inherit" className={styles.flexGrow} style={{textAlign: 'end', display: 'block'}} onClick={() => auth.signOut()}>
                        Odjava
                    </Button>
                </Toolbar>
            </AppBar>
            <ErrorSnackbar open={openError} message={errorMessage} />
            <Grid container direction="column" justifyContent="center" className={styles.mainContainer}>
                <form onSubmit={handleSubmit(submitForm)} className={styles.passwordForm}>
                    <FormGroup>
                        <TextField
                            className={styles.inputMargin}
                            label="NOVA LOZINKA"
                            variant="outlined"
                            {...register('newPassword', {
                                required: 'Nova lozinka je obavezna!',
                                minLength: {
                                    value: 8,
                                    message: 'Mora sadržavati minimalno 8 znakova',
                                },
                            })}
                            error={!!errors.newPassword}
                            type={spyEye.newPassword ? 'text' : 'password'}
                            helperText={errors.newPassword ? errors.newPassword.message : null}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={(event: MouseEvent<HTMLButtonElement>) => {
                                                setSpyEye({
                                                    ...spyEye,
                                                    newPassword: !spyEye.newPassword,
                                                });
                                            }}>
                                            {spyEye.newPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <TextField
                            className={styles.inputMargin}
                            label="PONOVI NOVU LOZINKU"
                            variant="outlined"
                            {...register('repeatNewPassword', {
                                required: 'Ponovljena nova lozinka je obavezna!',
                                validate: {
                                    matchesPreviousPassword: (value) => {
                                        const {newPassword} = getValues();
                                        return newPassword === value || 'Nove lozinke se moraju poklapati!';
                                    },
                                },
                            })}
                            error={!!errors.repeatNewPassword}
                            type={spyEye.repeatNewPassword ? 'text' : 'password'}
                            helperText={errors.repeatNewPassword ? errors.repeatNewPassword.message : null}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={(event: MouseEvent<HTMLButtonElement>) => {
                                                setSpyEye({
                                                    ...spyEye,
                                                    repeatNewPassword: !spyEye.repeatNewPassword,
                                                });
                                            }}>
                                            {spyEye.repeatNewPassword ? <Visibility /> : <VisibilityOff />}
                                        </IconButton>
                                    </InputAdornment>
                                ),
                            }}
                        />
                        <ButtonGroup className={styles.buttonGroup} variant="contained" color="secondary" aria-label="contained primary button group">
                            {!sending ? (
                                <Button type="submit">Pošalji</Button>
                            ) : (
                                <Button className={styles.uploadBtn} disabled color="primary" variant="contained">
                                    Slanje
                                    <CircularProgress size={24} className={styles.buttonProgress} />
                                </Button>
                            )}
                            <Button type="button" onClick={handleBack}>
                                Natrag
                            </Button>
                        </ButtonGroup>
                    </FormGroup>
                </form>

                <Snackbar
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    open={openSnackbar}
                    autoHideDuration={6000}
                    message={'Lozinka promjenjena'}
                />
            </Grid>
        </div>
    );
};

export default Profile;
