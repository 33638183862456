import {Button, Grid} from '@mui/material';
import {useState} from 'react';
import {AuthorizationCodeCallback} from 'react-oauth2-auth-code-flow';
import ClientOAuth2 from 'client-oauth2';
import jwt_decode, {JwtPayload} from 'jwt-decode';
import {useHistory} from 'react-router-dom';
import Loader from '../loader';
import {Replay} from '@mui/icons-material';
import useAuth from '../../Auth/useAuth';
import styles from './style.module.scss';

const oauthClient = new ClientOAuth2({
    clientId: process.env.REACT_APP_CLIENT_ID,
    clientSecret: process.env.REACT_APP_CLIENT_SECRET,
    accessTokenUri: process.env.REACT_APP_ACCESS_URL,
    authorizationUri: process.env.REACT_APP_AUTH_URL,
    redirectUri: `${process.env.REACT_APP_REDIRECT_URL}/redirect`,
    scopes: [],
});

type customJwtPayload = JwtPayload & {given_name: string; family_name: string; realm_access: {roles: Array<string>}};

const Redirect = () => {
    const history = useHistory();
    const auth = useAuth();
    const [showLoader, setShowLoader] = useState(true);

    const handleSuccess = async (accessToken, {state}) => {
        let forDecode = accessToken.accessToken;
        let token = {
            access_token: forDecode,
            expires: accessToken.expires,
            expires_in: accessToken.data.expires_in,
            refresh_token: accessToken.refreshToken,
            refresh_expires_in: accessToken.data.refresh_expires_in,
        };

        let decoded: customJwtPayload = jwt_decode(forDecode);

        let userName = decoded.given_name + ' ' + decoded?.family_name;

        let userType = decoded?.realm_access.roles.includes('admin') ? 'admin' : 'agent';

        auth?.signIn(token, userName, decoded?.realm_access.roles, () => {
            setTimeout(() => {
                state.resumeUrl ? history.push(processResumeUrl(state.resumeurl)) : history.push(`/${userType}`);
            }, 100);
        });
    };

    const handleError = () => {
        setShowLoader(false);
        history.push(`/`);
    };

    const handleTryAgain = () => {
        setTimeout(() => {
            history.push(`/`);
        });
    };

    const processResumeUrl = (url) => {
        if (typeof url === 'string') {
            return url;
        }
        if (typeof url === 'object') {
            return url[0];
        }
    };

    return (
        <Grid container direction="column" alignItems="center" justifyContent="center" className={styles.mainDiv}>
            <Loader appear={showLoader} />
            <AuthorizationCodeCallback
                oauthClient={oauthClient}
                onAuthSuccess={handleSuccess}
                onAuthError={handleError}
                render={({processing, error}) => (
                    <div>
                        {processing && <p>Authorizing now...</p>}
                        {error && (
                            <>
                                <p className={styles.error}>An error occurred: {error.message}</p>
                                <Grid container direction="row" alignItems="center" justifyContent="center">
                                    <Button variant="outlined" size="large" color="primary" className={styles.retryBtn} onClick={handleTryAgain}>
                                        Try again
                                        <Replay style={{marginLeft: '0.5rem'}} />
                                    </Button>
                                </Grid>
                            </>
                        )}
                    </div>
                )}
            />
        </Grid>
    );
};

export default Redirect;
