import {Card, CardContent, CardHeader, Grid, TableContainer, Table, TableCell, TableBody, TableRow} from '@mui/material';
import {IOrder} from '../../lib/interfaces/merchantLoanerInterfaces';
import {Branch} from 'pay-by-loan-administrator-api';
import {FC, PropsWithChildren} from 'react';
import jwt_decode, {JwtPayload} from 'jwt-decode';
import useAuth from '../../Auth/useAuth';
import styles from './style.module.scss';

type customJwtPayload = JwtPayload & {given_name: string; family_name: string; name: string; realm_access: {roles: Array<string>}};

type Props = {
    orderDetails?: IOrder;
    branchList?: Array<Branch>;
};

const OrderInfo: FC<PropsWithChildren<Props>> = ({orderDetails, branchList}) => {
    const auth = useAuth();

    const customerData = orderDetails?.customerData;
    const contact = orderDetails?.contact;
    const merchantData = orderDetails?.merchantData;

    let decoded: customJwtPayload = jwt_decode(auth.token.access_token);

    return (
        <div style={{width: '100%'}}>
            <Grid container justifyContent="center">
                <Grid item className={styles.mainContainer} xs={12} md={7}>
                    <Card raised>
                        <CardHeader title="Informacije o klijentu" />
                        <CardContent>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center">Ime i prezime</TableCell>
                                            <TableCell align="center">{`${customerData?.firstName} ${customerData?.lastName}`}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">OIB</TableCell>
                                            <TableCell align="center">{customerData?.personalNumber}</TableCell>
                                        </TableRow>
                                        {contact?.email && (
                                            <TableRow>
                                                <TableCell align="center">E-mail adresa</TableCell>
                                                <TableCell align="center">{contact?.email}</TableCell>
                                            </TableRow>
                                        )}
                                        {contact?.phone?.number && (
                                            <TableRow>
                                                <TableCell align="center">Broj tel.</TableCell>
                                                <TableCell align="center">{' +' + contact?.phone.dialCode + contact?.phone.number}</TableCell>
                                            </TableRow>
                                        )}
                                        <TableRow>
                                            <TableCell align="center">Grad</TableCell>
                                            <TableCell align="center">{customerData?.address.city}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Adresa</TableCell>
                                            <TableCell align="center">{customerData?.address.streetAddress}</TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <Grid container justifyContent="center">
                <Grid item className={styles.mainContainer} xs={12} md={7}>
                    <Card raised>
                        <CardHeader title="Informacije o zahtjevu" />
                        <CardContent>
                            <TableContainer>
                                <Table aria-label="simple table">
                                    <TableBody>
                                        <TableRow>
                                            <TableCell align="center">Iznos</TableCell>
                                            <TableCell align="center">
                                                HRK {orderDetails?.loanDetails.amount.toLocaleString(undefined, {minimumFractionDigits: 2})}
                                            </TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">{orderDetails?.status}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Način plaćanja</TableCell>
                                            <TableCell align="center">{orderDetails?.loanDetails.paymentProvider}</TableCell>
                                        </TableRow>
                                        <TableRow>
                                            <TableCell align="center">Agent</TableCell>
                                            <TableCell align="center">{orderDetails?.agentFullName}</TableCell>
                                        </TableRow>
                                        {decoded?.realm_access.roles.includes('admin') && (
                                            <TableRow>
                                                <TableCell align="center">Poslovnica</TableCell>
                                                <TableCell align="center">
                                                    {
                                                        branchList?.find((branch) => {
                                                            return branch.id === merchantData?.branchId;
                                                        })?.name
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </div>
    );
};

export default OrderInfo;
