import React, {useState, FC, PropsWithChildren} from 'react';
import {FormControlLabel, Checkbox, Grid, InputAdornment, TextField, Typography, Button} from '@mui/material';
import {DataGrid, GridColDef} from '@mui/x-data-grid';
import {AddCircle, Search} from '@mui/icons-material';
import Skeleton from '@mui/lab/Skeleton';
import {Agent, Branch} from 'pay-by-loan-administrator-api';
import styles from './style.module.scss';

type Props = {
    agentList: Array<Agent> | undefined;
    branchList: Array<Branch> | undefined;
    onAddNewUser: () => void;
    onEditUser: (usedData: Agent) => void;
    defaultPageNumber?: number;
    onPageNumberChanged: (pageNumber: number) => void;
};

const AgentList: FC<PropsWithChildren<Props>> = ({agentList, branchList, onAddNewUser, onEditUser, defaultPageNumber, onPageNumberChanged}) => {
    const [showInactive, setShowInactive] = useState(false);
    const [pageSize, setPageSize] = useState(10);
    const [searchTerm, setSearchTerm] = useState('');

    const editSearchTerm = (e: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(e.currentTarget.value);
    };

    const handleEditClick = (agentId: string) => {
        if (agentList) {
            onEditUser(agentList.filter((agent) => agent.id === agentId)[0]);
        }
    };

    const columnsSkeleton: GridColDef[] = [
        {
            field: ' ',
            headerName: ' ',
            width: 90,
            renderCell: () => {
                return <Skeleton variant="text" width={60} height={20} animation="wave" />;
            },
        },
        {
            field: 'username',
            headerName: 'Korisničko ime',
            width: 155,
            renderCell: () => {
                return <Skeleton variant="text" width={100} height={20} animation="wave" />;
            },
        },
        {
            field: 'firstName',
            headerName: 'Ime',
            width: 130,
            renderCell: () => {
                return <Skeleton variant="text" width={100} height={20} animation="wave" />;
            },
        },
        {
            field: 'lastName',
            headerName: 'Prezime',
            width: 130,
            renderCell: () => {
                return <Skeleton variant="text" width={100} height={20} animation="wave" />;
            },
        },
        {
            field: 'oib',
            headerName: 'OIB',
            width: 120,
            renderCell: () => {
                return <Skeleton variant="text" width={90} height={20} animation="wave" />;
            },
        },
        {
            field: 'email',
            headerName: 'Email',
            width: 230,
            renderCell: () => {
                return <Skeleton variant="text" width={150} height={20} animation="wave" />;
            },
        },
        {
            field: 'branchCode',
            headerName: 'Poslovnica',
            width: 200,
            renderCell: () => {
                return <Skeleton variant="text" width={120} height={20} animation="wave" />;
            },
        },
        {
            field: 'enabled',
            headerName: 'Status',
            width: 90,
            sortable: false,
            renderCell: () => {
                return <Skeleton variant="text" width={60} height={20} animation="wave" />;
            },
        },
    ];

    const columns: GridColDef[] = [
        {
            field: ' ',
            headerName: ' ',
            width: 90,
            renderCell: (params) => {
                return (
                    <strong>
                        <Button variant="contained" color="primary" size="small" onClick={() => handleEditClick(params.row.id)}>
                            Uredi
                        </Button>
                    </strong>
                );
            },
        },
        {field: 'username', headerName: 'Korisničko ime', width: 155},
        {field: 'firstName', headerName: 'Ime', width: 130},
        {field: 'lastName', headerName: 'Prezime', width: 130},
        {field: 'oib', headerName: 'OIB', width: 120},
        {field: 'email', headerName: 'Email', width: 230},
        {
            field: 'branchCode',
            headerName: 'Poslovnica',
            width: 200,
            renderCell: (params) => {
                return branchList?.find((branch) => {
                    return branch.id === params.row.branchCode;
                })?.name;
            },
        },
        {
            field: 'enabled',
            headerName: 'Status',
            width: 90,
            sortable: false,
            renderCell: (params) => {
                return <>{params.row.enabled ? 'Aktivan' : 'Neaktivan'}</>;
            },
        },
    ];

    const rows = (): Array<Agent> => {
        if (agentList === undefined) {
            return [...Array(10)].map((_, i) => {
                return {id: i.toString(), username: '', firstName: '', lastName: '', oib: '', enabled: true, branchCode: ''};
            });
        }

        return agentList
            .filter((user) => showInactive || user.enabled)
            .filter(
                (user) =>
                    user.firstName.toLowerCase().includes(searchTerm) ||
                    user.lastName.toLowerCase().includes(searchTerm) ||
                    user.oib.toLowerCase().includes(searchTerm) ||
                    user.username.toLowerCase().includes(searchTerm) ||
                    user.email?.toLowerCase().includes(searchTerm)
            );
    };

    const handlePageSizeChange = (newPageSize: number) => {
        setPageSize(newPageSize);
    };

    return (
        <Grid container direction="column" justifyContent="center" className={styles.mainContainer}>
            <Grid container direction="row" alignItems="center" justifyContent="center" style={{marginTop: '2rem'}}>
                <TextField
                    name="search"
                    onChange={editSearchTerm}
                    type="text"
                    label="Pretraži:"
                    value={searchTerm}
                    className={styles.filter}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search color="primary" />
                            </InputAdornment>
                        ),
                    }}
                />
            </Grid>
            <Grid container direction="row" alignItems="center" justifyContent="space-between" style={{marginTop: '2rem'}}>
                <Grid container direction="row" alignItems="center" style={{width: 'fit-content'}}>
                    <Button
                        color="primary"
                        style={{
                            textTransform: 'none',
                            borderRadius: '0.55rem',
                        }}
                        onClick={() => onAddNewUser()}>
                        <AddCircle />
                        <Typography
                            variant="body1"
                            style={{
                                color: '#000',
                                marginLeft: '0.5rem',
                            }}>
                            Dodaj novog agenta
                        </Typography>
                    </Button>
                </Grid>
                <FormControlLabel
                    control={<Checkbox checked={showInactive} onChange={() => setShowInactive(!showInactive)} name="checkedA" />}
                    label="Prikaži neaktivne agente"
                />
            </Grid>
            <div style={{width: '100%'}}>
                <DataGrid
                    autoHeight
                    disableSelectionOnClick
                    disableColumnMenu
                    disableDensitySelector
                    page={defaultPageNumber}
                    onPageChange={(newPageNumber: number) => {
                        onPageNumberChanged(newPageNumber);
                    }}
                    pageSize={pageSize}
                    onPageSizeChange={handlePageSizeChange}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                    pagination
                    rows={rows()}
                    columns={agentList !== undefined ? columns : columnsSkeleton}
                />
            </div>
        </Grid>
    );
};

export default AgentList;
