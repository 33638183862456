import {
    ButtonGroup,
    Button,
    Typography,
    Grid,
    CardMedia,
    FormGroup,
    TextField,
    Checkbox,
    FormControlLabel,
    FormHelperText,
    RadioGroup,
    Radio,
    FormLabel,
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {AddAPhoto, CheckCircleOutline, RadioButtonUnchecked} from '@mui/icons-material';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import moment from 'moment';
import styles from './style.module.scss';
import {DesktopDatePicker} from '@mui/x-date-pickers';

const IdCardDataScreen = ({data, onDataSubmit, onScanFront, onScanBack, onCancel, goBack, customerData, documentData}) => {
    const {control, handleSubmit} = useForm();

    const oibValidation = (oib: string) => {
        if (oib.match(/\d{11}/) === null) {
            return 'OIB se mora sastojati od 11 brojeva.';
        }
        let calculated = 10;
        for (const digit of oib.substring(0, 10)) {
            calculated += parseInt(digit);
            calculated %= 10;
            if (calculated === 0) {
                calculated = 10;
            }
            calculated *= 2;
            calculated %= 11;
        }
        let check = 11 - calculated;
        if (check === 10) {
            check = 0;
        }
        if ((check === parseInt(oib[10])) === true) {
            return true;
        }

        return 'OIB nije valjan.';
    };

    const defaultBirthDate = () => {
        return customerData?.dateOfBirth ?? data.dateOfBirth ?? new Date();
    };

    const defaultExpiryDate = () => {
        return documentData?.dateOfExpiry ?? data.dateOfExpiry ?? new Date();
    };

    const defaultCity = () => {
        if (customerData?.address.city) {
            return customerData?.address.city;
        } else if (data.residence?.city) {
            return data.residence?.city;
        } else return data.residence?.municipality;
    };

    return (
        <>
            <Typography variant="h5" color="secondary" className={styles.instructions}>
                Osobna iskaznica
            </Typography>
            <ButtonGroup style={{alignSelf: 'center', margin: '2rem 0 0 0'}}>
                <Button variant="outlined" color="secondary" onClick={onScanFront}>
                    {data.frontImage || documentData?.documentImages.front ? (
                        <CheckCircleOutline htmlColor={'#4caf50'} style={{marginRight: '0.5rem'}} />
                    ) : (
                        <RadioButtonUnchecked style={{marginRight: '0.5rem'}} />
                    )}
                    Slikaj prednju stranu
                    <AddAPhoto fontSize="large" style={{marginLeft: '0.5rem'}} />
                </Button>
                <Button variant="outlined" color="secondary" onClick={onScanBack}>
                    {data.backImage || documentData?.documentImages.back ? (
                        <CheckCircleOutline htmlColor={'#4caf50'} style={{marginRight: '0.5rem'}} />
                    ) : (
                        <RadioButtonUnchecked style={{marginRight: '0.5rem'}} />
                    )}
                    Slikaj stražnju stranu
                    <AddAPhoto fontSize="large" style={{marginLeft: '0.5rem'}} />
                </Button>
            </ButtonGroup>
            {(data.frontImage || documentData?.documentImages.front || data.backImage || documentData?.documentImages.back) && (
                <Typography variant="h5" color="secondary" className={styles.instructions}>
                    Prepravite krive podatke i nadopunite nepostojeće.
                </Typography>
            )}
            {(data.frontImage || documentData?.documentImages.front || data.backImage || documentData?.documentImages.back) && (
                <Grid container direction="row" spacing={10} justifyContent="center">
                    <Grid item xs={6} md={3}>
                        <FormGroup>
                            <Controller
                                name="firstName"
                                control={control}
                                defaultValue={customerData?.firstName ? customerData.firstName : data.firstName}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <TextField
                                        className={styles.inputMargin}
                                        label="IME"
                                        variant="outlined"
                                        value={value ?? ''}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error?.message || null}
                                    />
                                )}
                                rules={{required: 'Potrebno unijeti ime.'}}
                            />
                            <Controller
                                name="lastName"
                                control={control}
                                defaultValue={customerData?.lastName ? customerData.lastName : data.lastName}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <TextField
                                        className={styles.inputMargin}
                                        label="PREZIME"
                                        variant="outlined"
                                        value={value ?? ''}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error?.message || null}
                                    />
                                )}
                                rules={{
                                    required: 'Potrebno unijeti prezime.',
                                }}
                            />
                            <Controller
                                name="sex"
                                control={control}
                                defaultValue={customerData?.sex ? customerData.sex : data.sex}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <>
                                        <FormLabel>SPOL</FormLabel>
                                        <RadioGroup value={value ?? 'N'} onChange={onChange} row>
                                            <FormControlLabel value="F" control={<Radio />} label="Žensko" />
                                            <FormControlLabel value="M" control={<Radio />} label="Muško" />
                                            <FormControlLabel value="N" control={<Radio />} label="Ostalo" />
                                        </RadioGroup>
                                    </>
                                )}
                                rules={{
                                    required: 'Potrebno odabrati spol.',
                                }}
                            />
                            <Controller
                                name="number"
                                control={control}
                                defaultValue={documentData?.documentNumber ? documentData.documentNumber : data.number}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <TextField
                                        className={styles.inputMargin}
                                        type="number"
                                        label="BROJ ISKAZNICE"
                                        variant="outlined"
                                        value={value ?? ''}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error?.message || null}
                                    />
                                )}
                                rules={{
                                    required: 'Potrebno unijeti broj iskaznice.',
                                    pattern: {
                                        value: /^\d{9,9}$/i,
                                        message: 'Neispravan broj.',
                                    },
                                }}
                            />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Controller
                                    name="dateOfBirth"
                                    control={control}
                                    defaultValue={defaultBirthDate}
                                    render={({field: {onChange, value}, fieldState: {error}}) => (
                                        <DesktopDatePicker
                                            label="DATUM ROĐENJA"
                                            inputFormat="DD.MM.YYYY"
                                            value={value ?? ''}
                                            onChange={onChange}
                                            className={styles.inputMargin}
                                            renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message || null} />}
                                        />
                                    )}
                                    rules={{
                                        required: 'Potrebno unijeti datum rođenja.',
                                        validate: (value: string) => {
                                            if (value === 'Invalid Date') {
                                                return 'Datum nije ispravan.';
                                            }
                                            const years = moment().diff(value, 'years');
                                            if (years < 18) {
                                                return 'Osoba nema 18 godina.';
                                            }
                                        },
                                    }}
                                />
                                <Controller
                                    name="issuer"
                                    control={control}
                                    defaultValue={documentData?.issuedBy ? documentData.issuedBy : data.issuedBy}
                                    render={({field: {onChange, value}, fieldState: {error}}) => (
                                        <TextField
                                            className={styles.inputMargin}
                                            label="IZDAVATELJ"
                                            variant="outlined"
                                            value={value ?? ''}
                                            onChange={onChange}
                                            error={!!error}
                                            helperText={error?.message || null}
                                        />
                                    )}
                                    rules={{required: 'Potrebno unijeti izdavatelja.'}}
                                />
                                <Controller
                                    name="dateOfExpiry"
                                    control={control}
                                    defaultValue={defaultExpiryDate}
                                    render={({field: {onChange, value}, fieldState: {error}}) => (
                                        <DesktopDatePicker
                                            label="DATUM ISTEKA"
                                            inputFormat="DD.MM.YYYY"
                                            value={value ?? ''}
                                            onChange={onChange}
                                            className={styles.inputMargin}
                                            renderInput={(params) => <TextField {...params} error={!!error} helperText={error?.message || null} />}
                                        />
                                    )}
                                    rules={{
                                        required: 'Potrebno unijeti datum isteka.',
                                        validate: (value: string) => {
                                            if (value === 'Invalid Date') {
                                                return 'Datum nije ispravan.';
                                            }
                                        },
                                    }}
                                />
                            </LocalizationProvider>
                            <Controller
                                name="personalNumber"
                                control={control}
                                defaultValue={customerData?.personalNumber ? customerData.personalNumber : data.personalNumber}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <TextField
                                        className={styles.inputMargin}
                                        type="number"
                                        label="OIB"
                                        variant="outlined"
                                        value={value ?? ''}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error?.message || null}
                                    />
                                )}
                                rules={{
                                    required: 'OIB je potrebno unijeti.',
                                    validate: oibValidation,
                                }}
                            />
                            <Controller
                                name="streetAddress"
                                control={control}
                                defaultValue={customerData?.address.streetAddress ? customerData.address.streetAddress : data.residence?.streetAddress}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <TextField
                                        className={styles.inputMargin}
                                        label="ADRESA"
                                        variant="outlined"
                                        value={value ?? ''}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error?.message || null}
                                    />
                                )}
                                rules={{required: 'Potrebno unijeti adresu.'}}
                            />
                            <Controller
                                name="houseNumber"
                                control={control}
                                defaultValue={customerData?.address.houseNumber ? customerData.address.houseNumber : data.residence?.houseNumber}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <TextField
                                        className={styles.inputMargin}
                                        label="KUĆNI BROJ"
                                        variant="outlined"
                                        value={value ?? ''}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error?.message || null}
                                    />
                                )}
                                rules={{required: 'Potrebno unijeti kućni broj.'}}
                            />
                            <Controller
                                name="city"
                                control={control}
                                defaultValue={defaultCity()}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <TextField
                                        className={styles.inputMargin}
                                        label="GRAD"
                                        variant="outlined"
                                        value={value ?? ''}
                                        onChange={onChange}
                                        error={!!error}
                                        helperText={error?.message || null}
                                    />
                                )}
                                rules={{required: 'Potrebno unijeti grad.'}}
                            />
                            <Controller
                                name="consent"
                                control={control}
                                defaultValue={false}
                                render={({field: {onChange, value}, fieldState: {error}}) => (
                                    <>
                                        <FormControlLabel
                                            style={{color: error ? '#f44336' : ''}}
                                            control={<Checkbox className={styles.inputMargin} value={value} checked={value} onChange={onChange} />}
                                            label="Potvrđujem da su uneseni podaci ispravni"
                                        />
                                        <FormHelperText className={styles.errorText}>{error?.message}</FormHelperText>
                                    </>
                                )}
                                rules={{
                                    required: {
                                        value: true,
                                        message: 'Obavezno',
                                    },
                                }}
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6} md={5}>
                        {(data.frontImage || documentData?.documentImages.front) && (
                            <CardMedia
                                className={styles.media}
                                image={`data:image/jpeg;base64,${documentData?.documentImages.front ? documentData?.documentImages.front : data.frontImage}`}
                            />
                        )}
                        {(data.backImage || documentData?.documentImages.back) && (
                            <CardMedia
                                className={styles.media}
                                image={`data:image/jpeg;base64,${documentData?.documentImages.back ? documentData?.documentImages.back : data.backImage}`}
                            />
                        )}
                    </Grid>
                </Grid>
            )}

            <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="contained primary button group">
                <Button onClick={handleSubmit(onDataSubmit)}>Nastavi</Button>
                <Button onClick={goBack}>Natrag</Button>
                <Button onClick={onCancel}>Odustani</Button>
            </ButtonGroup>
        </>
    );
};

export default IdCardDataScreen;
