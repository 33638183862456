import React, {PropsWithChildren, useState, FC} from 'react';
import {useAdministratorControllerApi} from '../../api/apis';
import {
    Button,
    Grid,
    FormControlLabel,
    Switch,
    FormGroup,
    TextField,
    ButtonGroup,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Snackbar,
    CircularProgress,
    InputAdornment,
    FormLabel,
    Radio,
    RadioGroup,
    FormControl,
    MenuItem,
    FormHelperText,
} from '@mui/material';
import {Controller, useForm} from 'react-hook-form';
import {generate} from 'generate-password';
import ErrorSnackbar from '../errorSnackbar';
import useAuth from '../../Auth/useAuth';
import {ErrorType} from '../../lib/constants/errorTypes';
import jwt_decode, {JwtPayload} from 'jwt-decode';
import {Agent, Branch, NewAgentRequest, ResendEmailRequest, ResetPasswordRequest, UpdateAgentRequest} from 'pay-by-loan-administrator-api';
import {SnackbarState} from '../../lib/interfaces/payByLoanAdministratorInterfaces';
import Loader from '../loader';
import styles from './style.module.scss';

type CustomJwtPayload = JwtPayload & {
    merchant_code: string;
};

enum PasswordDeliveryType {
    EMAIL = 'EMAIL',
    GENERATE = 'GENERATE',
}

export enum AgentFormType {
    ADD,
    EDIT,
}

type Props = {
    type: AgentFormType;
    branchList: Array<Branch> | undefined;
    existingUser?: Agent;
    onFormClose: () => void;
    onUserAdded: (agent: Agent) => void;
    onUserEdited: (agent: Agent) => void;
};

const AgentForm: FC<PropsWithChildren<Props>> = ({type, branchList, existingUser, onFormClose, onUserAdded, onUserEdited}) => {
    const auth = useAuth();
    const administratorControllerApi = useAdministratorControllerApi();
    const {control, handleSubmit} = useForm();
    const [isActive, setIsActive] = useState(existingUser?.enabled === false ? false : true);
    const [passwordDialogOpen, setPasswordDialogOpen] = useState(false);
    const [newUserPassword, setNewUserPassword] = useState<string>();
    const [snackbarState, setSnackbarState] = useState<SnackbarState>({open: false});
    const [errorSnackbarState, setErrorSnackbarState] = useState<SnackbarState>({open: false});
    const [passwordDeliveryType, setPasswordDeliveryType] = useState(PasswordDeliveryType.GENERATE);
    const [showLoader, setShowLoader] = useState(false);

    let decoded = jwt_decode<CustomJwtPayload>(auth.token.access_token);

    const handlePasswordDeliveryTypeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const PASSWORD_DELIVERY_TYPE_MAP = {
            EMAIL: PasswordDeliveryType.EMAIL,
            GENERATE: PasswordDeliveryType.GENERATE,
        };
        setPasswordDeliveryType(PASSWORD_DELIVERY_TYPE_MAP[event.target.value]);
    };

    const handlePasswordDialogClose = () => {
        setPasswordDialogOpen(false);
        onFormClose();
    };

    const submitForm = (data: Agent) => {
        setShowLoader(true);
        if (type === AgentFormType.ADD) {
            let password: string | undefined = undefined;
            if (passwordDeliveryType === PasswordDeliveryType.GENERATE) {
                delete data.email;
                password = generate({
                    length: 8,
                    numbers: true,
                });
                setNewUserPassword(password);
            }

            const newAgentRequest: NewAgentRequest = {
                username: data.username,
                firstName: data.firstName,
                lastName: data.lastName,
                oib: data.oib,
                email: data.email,
                enabled: isActive,
                initialPassword: password,
                branchCode: data.branchCode,
            };

            administratorControllerApi().then((api) =>
                api
                    .createAgent(newAgentRequest)
                    .then((response) => {
                        setShowLoader(false);
                        onUserAdded(response.data);

                        if (passwordDeliveryType === PasswordDeliveryType.GENERATE) {
                            setPasswordDialogOpen(true);
                            return;
                        }
                        onFormClose();
                    })
                    .catch((err) => {
                        setShowLoader(false);
                        if (ErrorType[err.response.data.type]) {
                            showErrorMessage(ErrorType[err.response.data.type]);
                            return;
                        }
                        showErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                    })
            );
        }

        const updateAgentRequest: UpdateAgentRequest = {
            firstName: data.firstName,
            lastName: data.lastName,
            email: data.email,
            enabled: isActive,
            branchCode: data.branchCode,
        };

        administratorControllerApi().then(
            (api) =>
                existingUser &&
                api
                    .updateAgent(existingUser.id, updateAgentRequest)
                    .then((response) => {
                        setShowLoader(false);
                        onUserEdited(response.data);
                        onFormClose();
                    })
                    .catch((err) => {
                        setShowLoader(false);
                        if (ErrorType[err.response.data.type]) {
                            showErrorMessage(ErrorType[err.response.data.type]);
                            return;
                        }
                        showErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                    })
        );
    };

    const resetPassword = () => {
        setShowLoader(true);
        if (!existingUser) {
            return;
        }

        const password = generate({
            length: 8,
            numbers: true,
        });
        const resetPasswordRequest: ResetPasswordRequest = {
            userId: existingUser.id,
            password: password,
        };

        administratorControllerApi().then((api) =>
            api
                .resetPassword(resetPasswordRequest)
                .then(() => {
                    setShowLoader(false);
                    setNewUserPassword(password);
                    setPasswordDialogOpen(true);
                })
                .catch((err) => {
                    setShowLoader(false);
                    if (ErrorType[err.response.data.type]) {
                        showErrorMessage(ErrorType[err.response.data.type]);
                        return;
                    }
                    showErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                })
        );
    };

    const resendMail = () => {
        if (!existingUser) {
            return;
        }

        setSnackbarState({open: true, message: 'Šaljemo email.'});
        const resendEmailRequest: ResendEmailRequest = {
            userId: existingUser.id,
        };

        administratorControllerApi().then((api) =>
            api
                .resendEmail(resendEmailRequest)
                .then(() => {
                    onFormClose();
                })
                .catch((err) => {
                    if (ErrorType[err.response.data.type]) {
                        showErrorMessage(ErrorType[err.response.data.type]);
                        return;
                    }
                    showErrorMessage('Greška u sustavu. Pokušajte kasnije.');
                })
        );
    };

    const isValidOib = (oib: string): boolean => {
        if (oib.match(/\d{11}/) === null) {
            return false;
        }
        let calculated = 10;
        for (const digit of oib.substring(0, 10)) {
            calculated += parseInt(digit);
            calculated %= 10;
            if (calculated === 0) {
                calculated = 10;
            }
            calculated *= 2;
            calculated %= 11;
        }
        let check = 11 - calculated;
        if (check === 10) {
            check = 0;
        }
        if ((check === parseInt(oib[10])) === true) {
            return true;
        }

        return false;
    };

    const showErrorMessage = (message: string) => {
        setErrorSnackbarState({
            open: true,
            message: message,
        });
        setTimeout(() => setErrorSnackbarState({open: false}), 4000);
    };

    return (
        <Grid container direction="column" justifyContent="center" className={styles.mainContainer}>
            <Loader appear={showLoader} />
            <ErrorSnackbar open={errorSnackbarState.open} message={errorSnackbarState.message} />
            <FormControlLabel
                className={styles.inputMargin}
                control={<Switch defaultChecked={existingUser?.enabled} onChange={() => setIsActive(!isActive)} name="checkedA" />}
                label="Neaktivan/Aktivan"
            />
            <FormGroup>
                <Controller
                    name="username"
                    control={control}
                    defaultValue={existingUser?.username}
                    render={({field: {onChange, value}, fieldState: {error}}) => (
                        <TextField
                            className={styles.inputMargin}
                            label="KORISNIČKO IME"
                            variant="outlined"
                            value={value ? value : ''}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                            InputProps={{
                                startAdornment: type === AgentFormType.ADD && (
                                    <InputAdornment position="start" style={{marginRight: 0}}>
                                        {decoded?.merchant_code}_
                                    </InputAdornment>
                                ),
                            }}
                            disabled={type === AgentFormType.EDIT}
                        />
                    )}
                    rules={{
                        required: 'Korisničko ime ne može biti prazno.',
                    }}
                />
                <Controller
                    name="firstName"
                    control={control}
                    defaultValue={existingUser?.firstName}
                    render={({field: {onChange, value}, fieldState: {error}}) => (
                        <TextField
                            className={styles.inputMargin}
                            label="IME"
                            variant="outlined"
                            value={value ? value : ''}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{required: 'Ime ne može biti prazno.'}}
                />
                <Controller
                    name="lastName"
                    control={control}
                    defaultValue={existingUser?.lastName}
                    render={({field: {onChange, value}, fieldState: {error}}) => (
                        <TextField
                            className={styles.inputMargin}
                            label="PREZIME"
                            variant="outlined"
                            value={value ? value : ''}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                        />
                    )}
                    rules={{required: 'Prezime ne može biti prazno.'}}
                />
                <Controller
                    name="oib"
                    control={control}
                    defaultValue={existingUser?.oib}
                    render={({field: {onChange, value}, fieldState: {error}}) => (
                        <TextField
                            className={styles.inputMargin}
                            label="OIB"
                            variant="outlined"
                            value={value ? value : ''}
                            onChange={onChange}
                            error={!!error}
                            helperText={error ? error.message : null}
                            disabled={type === AgentFormType.EDIT}
                            type="number"
                        />
                    )}
                    rules={{
                        required: 'OIB je obavezan.',
                        validate: (value) => {
                            if (!isValidOib(value)) {
                                return 'Neispravan OIB';
                            }
                        },
                    }}
                />
                <Controller
                    name="branchCode"
                    control={control}
                    defaultValue={existingUser?.branchCode}
                    render={({field: {onChange, value}, fieldState: {error}}) => (
                        <FormControl variant="outlined" className={styles.inputMargin}>
                            <TextField select label="POSLOVNICA" value={value ? value : ''} onChange={onChange} error={!!error}>
                                {branchList &&
                                    branchList.map((branch, i) => (
                                        <MenuItem key={i} value={branch.id}>
                                            {branch.name}
                                        </MenuItem>
                                    ))}
                            </TextField>
                            {error && <FormHelperText style={{color: '#f44336'}}>Potrebno odabrati poslovnicu.</FormHelperText>}
                        </FormControl>
                    )}
                    rules={{required: true}}
                />
                {type === AgentFormType.ADD && (
                    <>
                        <FormLabel component="legend" style={{marginTop: '1rem'}}>
                            Odaberite način primanja lozinke
                        </FormLabel>
                        <RadioGroup aria-label="gender" name="gender1" value={passwordDeliveryType} onChange={handlePasswordDeliveryTypeChange}>
                            <FormControlLabel value={PasswordDeliveryType.GENERATE} control={<Radio />} label="Generiranje lozinke" />
                            <FormControlLabel value={PasswordDeliveryType.EMAIL} control={<Radio />} label="Slanje lozinke e-mailom" />
                        </RadioGroup>
                    </>
                )}
                {(passwordDeliveryType === PasswordDeliveryType.EMAIL || type === AgentFormType.EDIT) && (
                    <Controller
                        name="email"
                        control={control}
                        defaultValue={existingUser?.email}
                        render={({field: {onChange, value}, fieldState: {error}}) => (
                            <TextField
                                className={styles.inputMargin}
                                label="E-MAIL"
                                variant="outlined"
                                value={value ? value : ''}
                                onChange={onChange}
                                error={!!error}
                                helperText={error ? error.message : null}
                            />
                        )}
                        rules={
                            passwordDeliveryType === PasswordDeliveryType.EMAIL
                                ? {
                                      required: 'E-mail ne može biti prazan.',
                                      pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: 'E-mail netočan.',
                                      },
                                  }
                                : {
                                      pattern: {
                                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                          message: 'E-mail netočan.',
                                      },
                                  }
                        }
                    />
                )}
                <ButtonGroup className={styles.buttonGroup} variant="contained" color="primary" aria-label="contained primary button group">
                    <Button onClick={handleSubmit(submitForm)}>Spremi</Button>
                    <Dialog
                        open={passwordDialogOpen}
                        onClose={() => setPasswordDialogOpen(false)}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description">
                        <DialogTitle id="alert-dialog-title">Generirana lozinka za korisnika:</DialogTitle>
                        <DialogContent>
                            <DialogContentText id="alert-dialog-description" style={{fontSize: '2rem'}}>
                                {newUserPassword}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handlePasswordDialogClose} color="primary" autoFocus>
                                Zatvori
                            </Button>
                        </DialogActions>
                    </Dialog>
                    <Button onClick={onFormClose}>Odustani</Button>
                </ButtonGroup>
                {type === AgentFormType.EDIT && (
                    <ButtonGroup className={styles.buttonGroup} variant="text" color="primary" aria-label="text primary button group">
                        <Button onClick={resetPassword}>Resetiraj lozinku</Button>
                        {existingUser?.email && existingUser?.enabled && <Button onClick={resendMail}>Ponovno pošalji e-mail</Button>}
                        <Snackbar
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            open={snackbarState.open}
                            autoHideDuration={6000}
                            message={snackbarState.message}
                            action={
                                <React.Fragment>
                                    <CircularProgress color="secondary" />
                                </React.Fragment>
                            }
                        />
                    </ButtonGroup>
                )}
            </FormGroup>
        </Grid>
    );
};

export default AgentForm;
