import {useState} from 'react';
import IdCardDataScreen from './idCardDataScreen';
import {IDocument} from '../../lib/interfaces/simpleIdScanner';
import CameraScreen from './cameraScreen';

const IdCardScreen = ({onIdCardScanFinish, onCancel, goBack, customerData, documentData}) => {
    const [frontData, setFrontData] = useState<IDocument>();
    const [backData, setBackData] = useState<IDocument>();
    const [view, setView] = useState<string>('data');
    const [side, setSide] = useState<string>();

    const getData = () => {
        return {
            firstName: frontData?.firstName ? frontData.firstName : backData?.firstName,
            lastName: frontData?.lastName ? frontData.lastName : backData?.lastName,
            sex: frontData?.sex ? frontData.sex : backData?.sex,
            personalNumber: backData?.personalNumber,
            residence: backData?.residence,
            number: backData?.number ? backData.number : frontData?.number,
            dateOfExpiry: frontData?.dateOfExpiry,
            dateOfBirth: frontData?.dateOfBirth,
            frontImage: frontData?.images?.front,
            backImage: backData?.images?.back,
            portraitImage: frontData?.images?.portrait,
            issuedBy: backData?.issuedBy,
        };
    };

    const handleSubmitData = (data) => {
        delete data.consent;
        data.dateOfExpiry = new Date(data.dateOfExpiry).toISOString().substring(0, 10);
        data.dateOfBirth = new Date(data.dateOfBirth).toISOString().substring(0, 10);
        onIdCardScanFinish({
            customerData: {
                firstName: data.firstName.toUpperCase(),
                lastName: data.lastName.toUpperCase(),
                personalNumber: data.personalNumber,
                dateOfBirth: data.dateOfBirth,
                sex: data.sex,
                address: {
                    city: data.city.toUpperCase(),
                    streetAddress: data.streetAddress.toUpperCase(),
                    houseNumber: data.houseNumber.toUpperCase(),
                },
            },
            documentData: {
                documentNumber: data.number,
                dateOfExpiry: data.dateOfExpiry,
                issuer: data.issuer,
                documentImages: {
                    front: documentData?.documentImages.front ? documentData?.documentImages.front : frontData?.images?.front,
                    back: documentData?.documentImages.back ? documentData?.documentImages.back : backData?.images?.back,
                    portrait: documentData?.documentImages.portrait ? documentData?.documentImages.portrait : frontData?.images?.portrait,
                },
            },
        });
    };

    const handleScanFront = () => {
        setSide('FRONT');
        setView('id_scan');
    };

    const handleScanBack = () => {
        setSide('BACK');
        setView('id_scan');
    };

    const handleCancel = () => {
        onCancel();
    };

    const handleCameraCancel = () => {
        setView('data');
    };

    const addressFuncTemp = (residence: any) => {
        const municipality = residence.city ?? residence.municipality;
        const addressArray = residence.streetAddress.split(' ');
        const containsNumber =
            residence.streetAddress.includes('bb') ||
            residence.streetAddress.includes('BB') ||
            residence.streetAddress.includes('bB') ||
            residence.streetAddress.includes('Bb');
        const number = residence.streetAddress.match(/\d+/);
        if (number) {
            residence.houseNumber = number.join('');
        } else if (containsNumber) {
            residence.houseNumber = 'BB';
        } else residence.houseNumber = undefined;
        delete residence.streetAddress;
        if (addressArray.length > 1) addressArray.pop();
        residence.streetAddress = addressArray.join('');
        return {streetAddress: residence.streetAddress, houseNumber: residence.houseNumber, municipality: municipality};
    };

    const handleDataScanned = (data: IDocument) => {
        if (data.documentSide === 'FRONT') {
            if (data.residence) {
                data.residence = addressFuncTemp(data.residence);
            }
            setFrontData(data);
            setView('data');
            return;
        }

        if (data.residence) {
            data.residence = addressFuncTemp(data.residence);
        }
        setBackData(data);
        setView('data');
    };

    return (
        <>
            <>
                {view === 'data' && (
                    <IdCardDataScreen
                        data={getData()}
                        onDataSubmit={handleSubmitData}
                        onScanFront={handleScanFront}
                        onScanBack={handleScanBack}
                        onCancel={handleCancel}
                        goBack={goBack}
                        customerData={customerData}
                        documentData={documentData}
                    />
                )}
                {view === 'id_scan' && <CameraScreen side={side} onDataScanned={handleDataScanned} onCancel={handleCameraCancel} />}
            </>
        </>
    );
};

export default IdCardScreen;
